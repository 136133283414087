<template>
  <Dashboard v-if="user" title="Community Server">
    <div class="rounded-t bg-gray-950 flex items-center justify-start">
      <h3 class="text-text-primary text-[28px] font-medium">
        Public Servers
      </h3>
    </div>
    <div v-if="loading" class="flex justify-center py-10">
      <svg class="w-16 h-16 text-white animate-spin" fill="none"
          viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor">
        </path>
      </svg>
    </div>
    <div v-else class="w-full flex flex-col justify-center">
      <div class="space-x-2 py-4 flex justify-end">

        <button
          v-for="tag in getTags()"
          :key="tag"
          type="button"
          @click="toggleTag(tag.name)"
          class="rounded-md px-2.5 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset"
          :class="[
            isTagActive(tag.name)
              ? 'text-text-primary ring-primary-500 hover:ring-primary-300 bg-primary-500/10'
              : 'text-text-secondary ring-gray-700 hover:ring-gray-100',
          ]"
        >
          {{ tag.name }}
        </button>

        <div class="px-4">
          <Menu as="div" class="relative inline-block text-left">
            <MenuButton
              class="relative disabled:bg-gray-400 inline-flex items-center px-2 w-[192px] py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
            >
            <div class="flex-1 text-left">
              <span class="text-text-secondary">Region:</span> {{ getSelectedRegionText() }}
            </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem v-slot="{ active }" @click="selectRegion(null)">
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center whitespace-nowrap py-2 text-sm w-full justify-start',
                      ]"
                    >
                      All Regions
                      <CheckIcon v-if="!selectedRegion" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="region"
                    v-for="region in regions"
                    v-slot="{ active }"
                    @click="selectRegion(region)"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      <img class="h-4 mr-2" :src="'/img/icons/' + region + '.svg'">
                      {{ region }}
                      <CheckIcon v-if="selectedRegion === region" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <ul role="list" class="gap-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full h-full justify-center">
        <li class="flex-1 p-4 bg-gray-900 rounded-lg" v-for="communityServer in communityServers" :key="communityServer"
            :class="[ copySucceededVal === communityServer.id ?
                      'ring-green-500 ring-1' : 'ring-0']">
          <div class="flex h-full gap-4 text-white flex-1 duration-300 cursor-pointer">
            <div class="h-full aspect-[1/1] h-[92px] bg-cover bg-center rounded-md" v-bind:style="{ backgroundImage: 'url(/maps/' + communityServer.map_name + '_cs2.jpg)' }"></div>
            <div class="flex flex-col justify-between flex-1">
              <h3 class="text-lg font-semibold flex items-center w-full">
                <h1 class="flex-1 text-text-primary">{{ communityServer.name }}</h1>
                <span v-if="getServerMS(communityServer.location)" class="text-sm text-success-400 flex items-center gap-1" :id="communityServer.id">
                  <div class="h-[6px] w-[6px] rounded-full bg-success-400"/>
                  {{ getServerMS(communityServer.location) }}ms
                </span>
              </h3>

              <div class="flex items-center gap-1">
                <img class="h-3 w-3 rounded-full object-cover" :src="'/img/icons/' + communityServer.region + '.svg'">
                <div class="text-sm text-text-secondary">{{ getRegionLocation(communityServer.region) }}</div>
                <div class="text-text-disabled text-sm mx-1">•</div>
                <img class="h-3 rounded-full" :src="`/maps/${communityServer.map_name}_badge.png`" alt="">
                <div class="text-sm text-text-secondary">{{ communityServer.map_name }}</div>
              </div>

              <h2 class="text-gray-300 text-sm flex">
                <div class="flex items-center gap-3 w-full">
                  <p class="text-sm text-left text-text-secondary flex-1">
                    <span class="text-text-primary">{{ communityServer.players_online }}</span>/{{ communityServer.player_max }}
                    players
                  </p>
                  <div class="flex items-center justify-center hover:bg-primary-600 h-8 w-8 bg-gray-800 rounded-md group"
                    @click="copySucceeded(communityServer.id)"
                    v-clipboard:copy="
                        `connect ${communityServer.ip}:${communityServer.port};`
                    ">
                    <img class="h-4 w-4 cursor-pointer duration-300 group-hover:scale-110 duration-300" src="../assets/icons/copy.svg">
                  </div>
                  <a class="flex items-center justify-center bg-primary-600 rounded-md hover:bg-primary-600 h-8 w-8 group" :href="`steam://connect/${communityServer.raw_ip}:${communityServer.port}`">
                    <img class="h-4 w-4 cursor-pointer duration-300 group-hover:scale-110 duration-300 brightness-200" src="../assets/icons/play.svg">
                  </a>
                </div>
              </h2>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </Dashboard>

  <div v-else class="flex flex-col h-full items-center w-full bg-gray-950">
    <div class="w-full min-h-[56px] bg-gray-900 border-b border-gray-800 relative gap-4 flex items-center justify-end pr-9">
      <img class="h-6 absolute left-6 top-0 md:left-1/2 md:-translate-x-1/2 translate-y-[16px]" src="../assets/logos/refrag-horz-fill.svg" alt="Refrag Logo">
      <router-link to="/login" class="px-3 py-2.5 bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700">
        Login
      </router-link>
      <router-link to="/register" class="px-3 py-2.5 bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500">
        Signup
      </router-link>
    </div>
    <AlertPopup />
    <div v-if="loading" class="flex justify-center py-10">
      <svg class="w-16 h-16 text-white animate-spin" fill="none"
          viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              fill="currentColor">
        </path>
      </svg>
    </div>
    <div v-else class="w-full flex flex-col items-end justify-center bg-gray-950 p-9">
      <div class="rounded-t bg-gray-950 flex w-full">
        <h3 class="text-text-primary text-[36px] font-semibold">Public Servers by Refrag</h3>
      </div>
      <div class="space-x-2 py-4 flex justify-end">
        <button
          v-for="tag in getTags()"
          :key="tag"
          type="button"
          @click="toggleTag(tag.name)"
          class="rounded-md px-2.5 py-1 text-sm font-semibold shadow-sm ring-1 ring-inset"
          :class="[
            isTagActive(tag.name)
              ? 'text-text-primary ring-primary-500 hover:ring-primary-300 bg-primary-500/10'
              : 'text-text-secondary ring-gray-700 hover:ring-gray-100',
          ]"
        >
          {{ tag.name }}
        </button>

        <div class="px-4">
          <Menu as="div" class="relative inline-block text-left">
            <MenuButton
              class="relative disabled:bg-gray-400 inline-flex items-center px-2 w-[192px] py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700"
            >
            <div class="flex-1 text-left">
              <span class="text-text-secondary">Region:</span> {{ getSelectedRegionText() }}
            </div>
              <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div class="py-1 flex flex-col px-4">
                  <MenuItem v-slot="{ active }" @click="selectRegion(null)">
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center whitespace-nowrap py-2 text-sm w-full justify-start',
                      ]"
                    >
                      All Regions
                      <CheckIcon v-if="!selectedRegion" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                  <MenuItem
                    :key="region"
                    v-for="region in regions"
                    v-slot="{ active }"
                    @click="selectRegion(region)"
                  >
                    <a
                      href="#"
                      :class="[
                        active ? 'text-text-primary' : 'text-text-secondary',
                        'group flex items-center py-2 text-sm w-full justify-start',
                      ]"
                    >
                      <img class="h-4 mr-2" :src="'/img/icons/' + region + '.svg'">
                      {{ region }}
                      <CheckIcon v-if="selectedRegion === region" class="h-5 w-5 ml-auto text-primary-400" />
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
      <div class="flex gap-6 w-full h-full relative">
        <ul role="list" class="flex-1 gap-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full h-full justify-center md:mr-[231px]">
          <li class="flex-1 p-4 bg-gray-900 rounded-lg" v-for="communityServer in communityServers" :key="communityServer"
              :class="[ copySucceededVal === communityServer.id ?
                          'ring-green-500 ring-1' : 'ring-0']">
            <div class="flex h-full gap-4 text-white flex-1 duration-300 cursor-pointer">
              <div class="h-full aspect-[1/1] h-[92px] bg-cover bg-center rounded-md" v-bind:style="{ backgroundImage: 'url(/maps/' + communityServer.map_name + '_cs2.jpg)' }"></div>
              <div class="flex flex-col justify-between flex-1">
                <h3 class="text-lg font-semibold flex items-center w-full">
                  <h1 class="flex-1 text-text-primary">{{ communityServer.name }}</h1>
                  <span v-if="getServerMS(communityServer.location)" class="text-sm text-success-400 flex items-center gap-1" :id="communityServer.id">
                    <div class="h-[6px] w-[6px] rounded-full bg-success-400"/>
                    {{ getServerMS(communityServer.location) }}ms
                  </span>
                </h3>

                <div class="flex items-center gap-1">
                  <img class="h-3 w-3 rounded-full object-cover" :src="'/img/icons/' + communityServer.region + '.svg'">
                  <div class="text-sm text-text-secondary">{{ getRegionLocation(communityServer.region) }}</div>
                  <div class="text-text-disabled text-sm mx-1">•</div>
                  <img class="h-3 rounded-full" :src="`/maps/${communityServer.map_name}_badge.png`"  alt="">
                  <div class="text-sm text-text-secondary">{{ communityServer.map_name }}</div>
                </div>

                <h2 class="text-gray-300 text-sm flex">
                  <div class="flex items-center gap-3 w-full">
                    <p class="text-sm text-left text-text-secondary flex-1">
                      <span class="text-text-primary">{{ communityServer.players_online }}</span>/{{ communityServer.player_max }}
                      players
                    </p>
                    <div class="flex items-center justify-center hover:bg-primary-600 h-8 w-8 bg-gray-800 rounded-md group"
                      @click="copySucceeded(communityServer.id)"
                      v-clipboard:copy="
                          `connect ${communityServer.ip}:${communityServer.port};`
                      ">
                      <img class="h-4 w-4 cursor-pointer duration-300 group-hover:scale-110 duration-300" src="../assets/icons/copy.svg">
                    </div>
                    <a class="flex items-center justify-center bg-primary-600 rounded-md hover:bg-primary-600 h-8 w-8 group" :href="`steam://connect/${communityServer.raw_ip}:${communityServer.port}`">
                      <img class="h-4 w-4 cursor-pointer duration-300 group-hover:scale-110 duration-300 brightness-200" src="../assets/icons/play.svg">
                    </a>
                  </div>
                </h2>
              </div>
            </div>
          </li>
        </ul>
        <div class="hidden md:flex flex-col absolute right-0 bg-gray-900 rounded-lg p-4 w-[207px]">
          <img src="../assets/logos/refrag-ad.png" alt="ads" class="rounded-md w-[175px] mb-4">
          <h1 class="text-text-primary text-[24px] font-semibold">Elevate your <br/>CS2 Training</h1>
          <p class="text-text-secondary text-xs mt-2 mb-[84px]">Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.</p>
          <router-link to="/register" class="px-3 py-2.5 flex items-center justify-center bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500">
            Sign up For Free
          </router-link>
          <a href="https://wiki.refrag.gg/en/home" target="_blank" class="px-3 py-2.5 mt-3 flex items-center justify-center bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700">
            Learn More
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { watch, ref } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3";
import { CheckIcon, ClipboardDocumentIcon, PlayIcon } from "@heroicons/vue/20/solid";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import { useStore } from "vuex";
import AlertPopup from "../components/AlertPopup.vue"
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import { useHead } from '@unhead/vue'

const communityServers = ref()
const auth = useAuth();
const store = useStore();
let user = auth.user();
let loading = ref(true);
const copySucceededVal = ref('')
const selectedRegion = ref('');
const selectedTags = ref([]);
const regions = ref([]);
const modInfo = ref();
const showMs = ref(true);

useHead({
  title: "Refrag | Community Servers",
  description: "Besides Refrag's custom training servers, we also provide community servers for you to play and have fun with your friends.",
})

function copySucceeded(value) {
  showCopyPopup();
  copySucceededVal.value = value;
  setTimeout(() => {
    copySucceededVal.value = '';
  }, 1000);
}

function getServerMS(value) {
  if(!store.state.servers) return;

  return store.state.servers.find(server => server.value === value)?.ms;
}

function showCopyPopup() {
  store.commit("showMessage", {messageType: "success", messageText: "Link Copied Successfully"})
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

function selectRegion(value) {
  selectedRegion.value = value;
  getCommunityServers();
}

function getTags() {
  return modInfo.value?.mods.find(({ name }) => name === 'Deathmatch')?.tags
}

function getSelectedRegionText() {
  if(selectedRegion.value) return selectedRegion.value
  return "All"
}

function isTagActive(name) {
  return selectedTags.value?.includes(name);
}

function getRegions() {
  communityServers.value.forEach(server => {
    if(regions.value.includes(server.region)) return;
    regions.value.push(server.region)
  });
}

function getCommunityServers() {
  axios({
    url: "/community_servers",
    method: "GET",
    params: {
      "filters[region]": selectedRegion.value,
      "filters[tags]": getSelectedTags(),
    },
  }).then((response) => {
      communityServers.value = response.data.sort((a, b) => b.players_online - a.players_online);
      getRegions();
      loading.value = false;
  });
}

function getSelectedTags() {
  if (selectedTags.value[0]) return selectedTags.value;
  return "";
}

function toggleTag(name) {
  if (selectedTags.value?.includes(name)) {
    selectedTags.value = selectedTags.value.filter((e) => e !== name);
  } else {
    selectedTags.value.push(name);
  }
  getCommunityServers();
}

function getRegionLocation(region) {
  switch (region) {
    case 'NA':
      return 'Dallas'
    case 'EU':
      return 'Frankfurt'
    case 'AUS':
      return 'Sydney'
    case 'HK':
      return 'Hong Kong'
    case 'BR':
      return 'São Paulo'
    case 'ZA':
      return 'Johannesburg'
    case 'SG':
      return 'Singapore'
  }
}

function loadModInfo() {
  axios.get(`mods`).then(({data}) => {
    let tempModInfo = { mods: [], tags: [] }
    tempModInfo.mods = data.mods;
    tempModInfo.tags = data.tags;
    modInfo.value = tempModInfo
  })
}

loadModInfo();
getCommunityServers();

</script>

<style>
.aspect-video {
  aspect-ratio: 16 / 9;
}
.custom-flex {
  flex: 1 1 1px;
}
</style>
