<template>
  <Dashboard v-if="user" title="Community Server">
    <div class="py-9 w-full flex items-center justify-center flex-col gap-6">
      <div class="w-full lg:w-[750px] bg-gray-900 rounded-lg relative">
        <div class="flex w-full px-6 py-4 relative border-b-2 border-gray-950">
          <div class="w-full h-full absolute top-0 left-0 overflow-hidden z-10">
            <div
              class="w-full h-full topography-color opacity-20 bg-[url('/img/icons/topography.svg')]"
            ></div>
          </div>
          <div class="flex-col lg:flex-row flex items-center justify-center gap-4 w-full cursor-pointer z-50"
              @click="router.push(`/user/${highScore?.user?.uuid}`)"
          >
            <div class="flex items-center justify-center lg:justify-start">
              <img
                :src="highScore?.user?.steam_profile?.avatarfull"
                alt="Avatar"
                class="w-20 h-20 rounded-full"
              />
            </div>

            <div class="flex flex-col justify-center gap-2 flex-1">
              <h1 class="text-xl font-medium text-text-primary text-center lg:text-left">
                {{ highScore?.user?.steam_profile?.personaname }}
              </h1>
              <div class="flex items-center justify-center lg:justify-start gap-2 text-xs">
                <h1 class="text-text-secondary">{{ highScore?.mod.friendly_name }}</h1>
                <h1 class="text-text-disabled">•</h1>
                <h1 class="text-text-secondary">Season {{ highScore?.season - 1 }}</h1>
                <h1 class="text-text-disabled">•</h1>
                <h1 class="text-text-secondary">
                  {{ moment(highScore?.created_at).format("MMM D, YYYY") }}
                </h1>
              </div>
            </div>

            <h1 class="score-text text-center lg:text-left">{{ score }}</h1>
          </div>
        </div>

        <div class="flex flex-col p-6 gap-4">
          <div class="flex items-center gap-2">
            <h1 class="text-text-primary text-xl font-bold flex-1">Replay</h1>
            <div
              v-clipboard:copy="`https://play.refrag.gg/leaderboards/${highScore?.uuid}`"
              v-clipboard:success="copySucceeded"
              target="_blank"
              class="bg-primary-600 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
            >
              <ArrowUpTrayIcon class="w-4 h-4 text-text-primary" />
              Share
            </div>
          </div>

          <div
            class="flex-[7] aspect-[16/9] w-full bg-gray-800 rounded-lg overflow-hidden"
          >
            <iframe
              v-if="highScore?.allstar_clip?.status == 'processed' || highScore?.allstar_clip?.status == 'submitted'"
              :key="highScore?.allstar_clip?.status"
              class="w-full h-full"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              :src="highScore?.allstar_clip?.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
            >
            </iframe>
            <img v-else-if="highScore?.allstar_clip?.status == 'failed'" src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/cfb3370d-92be-4245-5048-75e430af0e00/public" alt="Loading" class="w-full h-full" />
            <img v-else src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/wordpress.refrag.gg/2024/05/processing.jpg/public" alt="Loading" class="w-full h-full" />
          </div>
        </div>
      </div>

      <div class="flex gap-4 items-center p-6 bg-gray-900 rounded-lg w-full lg:w-[750px]">
        <div class="flex flex-col gap-3 flex-1">
          <h1 class="text-text-primary text-xl font-bold flex-1">Can you do better?</h1>
          <h1 class="text-text-secondary text-sm">
            Showcase your skills and reach the top of the leaderboard!
          </h1>
        </div>
        <button
          @click="launchGamemode()"
          class="bg-primary-600 hover:bg-primary-500 rounded-md text-text-primary text-xs font-medium px-4 py-2"
        >
          Play Now
        </button>
      </div>
    </div>
  </Dashboard>

  <div v-else class="flex flex-col h-full items-center w-full bg-gray-950">
    <div
      class="w-full min-h-[56px] bg-gray-900 border-b border-gray-800 relative gap-4 flex items-center justify-end pr-9"
    >
      <img
        class="h-6 absolute left-6 top-0 lg:left-1/2 lg:-translate-x-1/2 translate-y-[16px]"
        src="../../assets/logos/refrag-horz-fill.svg"
        alt="Refrag Logo"
      />
      <router-link
        to="/login"
        class="px-3 py-2.5 bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700"
      >
        Login
      </router-link>
      <router-link
        to="/register"
        class="px-3 py-2.5 bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500"
      >
        Signup
      </router-link>
    </div>
    <AlertPopup />
    <div v-if="loading" class="flex justify-center py-10">
      <svg class="w-16 h-16 text-white animate-spin" fill="none" viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
    <div
      v-else
      class="py-9 w-full flex items-center justify-center flex-col gap-6 bg-gray-950 px-4"
    >
    <div class="w-full lg:w-[750px] bg-gray-900 rounded-lg relative">
        <div class="flex w-full px-6 py-4 relative border-b-2 border-gray-950">
          <div class="w-full h-full absolute top-0 left-0 overflow-hidden z-10">
            <div
              class="w-full h-full topography-color opacity-20 bg-[url('/img/icons/topography.svg')]"
            ></div>
          </div>
          <div class="flex-col lg:flex-row flex items-center justify-center gap-4 w-full cursor-pointer"
              @click="router.push(`/profile/${highScore?.user?.uuid}`)"
          >
            <div class="flex items-center justify-center lg:justify-start">
              <img
                :src="highScore?.user?.steam_profile?.avatarfull"
                alt="Avatar"
                class="w-20 h-20 rounded-full"
              />
            </div>

            <div class="flex flex-col justify-center gap-2 flex-1">
              <h1 class="text-xl font-medium text-text-primary">
                {{ highScore?.user?.steam_profile?.personaname }}
              </h1>
              <div class="flex items-center justify-center lg:justify-start gap-2 text-xs">
                <h1 class="text-text-secondary">{{ highScore?.mod.friendly_name }}</h1>
                <h1 class="text-text-disabled">•</h1>
                <h1 class="text-text-secondary">Season {{ highScore?.season }}</h1>
                <h1 class="text-text-disabled">•</h1>
                <h1 class="text-text-secondary">
                  {{ moment(highScore?.created_at).format("MMM D, YYYY") }}
                </h1>
              </div>
            </div>

            <h1 class="score-text text-center lg:text-left">{{ score }}</h1>
          </div>
        </div>

        <div class="flex flex-col p-6 gap-4">
          <div class="flex items-center gap-2">
            <h1 class="text-text-primary text-xl font-bold flex-1">Replay</h1>
            <div
              v-clipboard:copy="`https://play.refrag.gg/leaderboards/${highScore?.uuid}`"
              v-clipboard:success="copySucceeded"
              target="_blank"
              class="bg-primary-600 duration-300 px-4 py-2 hover:bg-primary-500 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md"
            >
              <ArrowUpTrayIcon class="w-4 h-4 text-text-primary" />
              Share
            </div>
          </div>

          <div
            class="flex-[7] aspect-[16/9] w-full bg-gray-800 rounded-lg overflow-hidden"
          >
            <iframe
              v-if="highScore?.allstar_clip?.status == 'processed' || highScore?.allstar_clip?.status == 'submitted'"
              :key="highScore?.allstar_clip?.status"
              class="w-full h-full"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              :src="highScore?.allstar_clip?.clip_url + `&autoplay=1&mute=1&steamid64=${user?.steam_id}`"
            >
            </iframe>
            <img v-else src="https://imagedelivery.net/5wML_ikJr-qv52ESeLE6CQ/wordpress.refrag.gg/2024/05/processing.jpg/public" alt="Loading" class="w-full h-full" />
          </div>
        </div>
      </div>

      <div class="flex w-full lg:w-[750px]">
        <div
          class="flex gap-6 w-full bg-gray-900 items-center rounded-lg flex-col lg:flex-row px-4 lg:px-0"
        >
          <img
            src="../../assets/logos/refrag-ad.png"
            alt="ads"
            class="rounded-md w-[200px] aspect-[1]"
          />
          <div class="flex flex-col gap-3 justify-center flex-1 pt-4">
            <h1 class="text-text-primary text-[20px] font-semibold">
              Unlock Your CS2 Potential Today!
            </h1>
            <p class="text-text-secondary text-xs">
              Join the world's best players on Refrag's CS2 training platform, with:

              <ul class="list-disc text-xs text-text-secondary ml-4">
                <li>20+ innovative in-game aim training modes</li>
                <li>Educational videos from Pro players</li>
                <li>Warmup routines</li>
                <li>2D Demo Viewer to review your gameplay</li>
                <li>Bootcamp feature to master any map</li>
              </ul>
            </p>
          </div>
          <div
            class="flex flex-col lg:flex-row lg:flex-col gap-3 lg:px-8 pb-4 w-full lg:w-auto"
          >
            <router-link
              to="/register"
              class="px-6 py-2.5 flex w-full lg:w-auto items-center justify-center bg-primary-600 rounded-md text-text-primary text-xs font-medium hover:bg-primary-500"
            >
              Sign up For Free
            </router-link>
            <a
              href="https://refrag.gg/"
              target="_blank"
              class="px-6 py-2.5 lg:mt-3 flex w-full lg:w-auto items-center justify-center bg-gray-800 rounded-md text-text-primary text-xs font-medium hover:bg-gray-700"
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    :showMapSelector="showMapSelector"
    @close="showServerModal = false"
  />
</template>

<script setup>
import axios from "axios";
import { ref, computed } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3";
import { ArrowUpTrayIcon, ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import { useStore } from "vuex";
import AlertPopup from "../../components/AlertPopup.vue";
import moment from "moment";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@unhead/vue";
import StartServerModal from "../../components/StartServerModal.vue";

const route = useRoute();
const router = useRouter();
const auth = useAuth();
const store = useStore();
let user = auth.user();
let loading = ref(true);
const highScore = ref();
const score = ref(0)
const serverInfo = ref({})
const showServerModal = ref(false)
const showMapSelector = ref(false)

function copySucceeded() {
  store.commit("showMessage", {
    messageType: "success",
    messageText: "Link Copied Successfully",
  });
  setTimeout(() => {
    store.commit("hideMessage");
  }, 3000);
}

function launchGamemode() {
  serverInfo.value = {
    mode: highScore.value.mod.name,
    cs_map: highScore.value.cs_map,
  }

  showServerModal.value = true;
}

const pageTitle = computed(() => {
  return highScore.value?.score
    ? `Refrag | ${highScore.value?.user?.steam_profile?.personaname}'s High Score in ${highScore.value?.mod?.friendly_name} on ${highScore.value?.cs_map.friendly_name}`
    : "Refrag | Leaderboard";
});

const clipEmbedUrl = computed(() => {
  return highScore.value?.allstar_clip?.thumb_url
    .replace("thumbs", "clips")
    .replace("_thumb.jpg", ".mp4")
    .replace("media.allstar.gg", "mediacdn.allstar.gg");
});

useHead({
  title: () => pageTitle.value,
  meta: [
    {
      name: "description",
      content:
        "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
    {
      name: 'og:title',
      content: () => pageTitle.value
    },
    {
      name: "og:description",
      content:
        "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
    {
      name: "og:video:url",
      content: () => clipEmbedUrl.value,
    },
    {
      name: "og:video",
      content: () => clipEmbedUrl.value,
    },
    {
      name: 'twitter:video',
      content: () => clipEmbedUrl.value,
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:domain',
      content: 'play.refrag.gg',
    },
    {
      name: "og:image",
      content: () => clipEmbedUrl.value,
    },
    {
      name: 'twitter:image',
      content: () => clipEmbedUrl.value,
    },
    {
      name: "og:type",
      content: "video",
    },
  ],
});

function loadHighScore() {
  axios.get(`/high_scores/${route.params.uuid}`).then((response) => {
    highScore.value = response.data;

    if (highScore.value.mod.friendly_name === "Prefire" || highScore.value.mod.friendly_name === "Rapid by Wooting") {
      score.value = (highScore.value.score / 1000).toFixed(2)
    } else {
      score.value = highScore.value.score
    }

    loading.value = false;
  });
}

loadHighScore();
</script>

<style>
.aspect-video {
  aspect-ratio: 16 / 9;
}
.custom-flex {
  flex: 1 1 1px;
}
.topography-color {
  filter: invert(99%) sepia(33%) saturate(5352%) hue-rotate(319deg) brightness(110%)
    contrast(105%);
}

.score-text {
  background: linear-gradient(0deg, #f7aa4c 0%, #f7e64c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: "liga" off, "clig" off;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Desktop/Display/Small */
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 100% */
}
</style>
