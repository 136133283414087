<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import ModalWindow from "../../components/ModalWindow.vue";
import DemoForm from "../../components/Restrat/Demos/DemoForm.vue";
import CopyToTeam from "../../components/CopyToTeam.vue";
import DeleteDemo from "../../components/DeleteDemo.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { TrashIcon, ChevronDownIcon, UserGroupIcon, ArrowUpTrayIcon } from "@heroicons/vue/20/solid";
</script>

<template>
  <Dashboard title="Restrat Demos" class="relative">

    <div class="rounded-t bg-gray-950 flex items-center justify-end">
      <h3 class="text-text-primary text-[28px] font-medium flex-grow pb-6">
        {{ activeTeam.name }} Demos
      </h3>
      <div class="flex justify-end space-x-3">
        <button @click="toggleDemoModal" class="whitespace-nowrap flex gap-x-2 items-center px-2 md:px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <ArrowUpTrayIcon class="w-4 h-4"/>
          Upload Demo
        </button>
        <Menu as="div" class="relative inline-block text-left">
          <div class="has-tooltip">
            <MenuButton
              :disabled="!isDemoSelected"
              class="relative disabled:bg-gray-400 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Actions
              <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <span
              v-show="!isDemoSelected"
              class="tooltip rounded shadow-md bg-gray-700 text-white p-3 mt-10 -ml-28"
            >
              Please Select a demo
            </span>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute z-10 mt-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }" @click="toggleCopyModal">
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    ]"
                  >
                    <UserGroupIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Copy to Team
                  </a>
                </MenuItem>
                <MenuItem
                  v-if="isCaptain"
                  v-slot="{ active }"
                  @click="toggleDeleteModal"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm',
                    ]"
                  >
                    <TrashIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- <button
          type="button"
          v-on:click="toggleDemoModal"
          class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          New Demo +
        </button> -->
      </div>
    </div>

    <div class="flex flex-col w-full md:hidden">
      <div class="py-5 flex items-center gap-2 text-sm text-text-primary border-t border-gray-800">
        <input
          type="checkbox"
          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 pl-0"
          v-model="allSelected"
        />
        Select All
      </div>
      <MobileDemoRow
        ref="demoRow"
        :demo="demo"
        v-for="demo in sortedDemos"
          :key="demo.id"
          :all-selected="allSelected"
          :numOfDemos="numOfDemos"
          @update-all-selected="onUpdateAllSelected"
          @push-selected-demo="onPushSelectedDemo"
          @remove-selected-demo="onRemoveSelectedDemo"
      />
    </div>

    <div class="bg-gray-950 px-5 sm:px-6 hidden md:block">
      <div class="sm:-mx-6">
        <div class="align-middle inline-block min-w-full">
          <table class="min-w-full divide-y divide-gray-800 table-auto border-b border-gray-800">
            <thead class="bg-gray-950">
              <tr>
                <th scope="col" class="flex items-start my-4">
                  <input
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 pl-0"
                    v-model="allSelected"
                  />
                </th>
                <th
                  scope="col"
                  class="text-left text-xs text-text-secondary uppercase font-light tracking-wider"
                  @click="setSortingKey('map_name')"
                  @keydown="setSortingKey('map_name')"
                >
                  Map
                </th>
                <th
                  scope="col"
                  class="text-left text-xs text-text-secondary font-light uppercase tracking-wider"
                >
                  Teams
                </th>
                <th
                  scope="col"
                  class="text-left text-xs text-text-secondary font-light uppercase tracking-wider"
                >
                  Score
                </th>
                <th
                  scope="col"
                  class="text-left text-xs text-text-secondary font-light uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="text-left text-xs font-medium text-gray-200 uppercase tracking-wider"
                ></th>
              </tr>
            </thead>
            <tbody>
              <DemoRow
                ref="demoRow"
                :demo="demo"
                v-for="demo in sortedDemos"
                  :key="demo.id"
                  :all-selected="allSelected"
                  :numOfDemos="numOfDemos"
                  @update-all-selected="onUpdateAllSelected"
                  @push-selected-demo="onPushSelectedDemo"
                  @remove-selected-demo="onRemoveSelectedDemo"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="flex flex-col justify-center items-center gap-3 text-text-primary w-full mt-6">
      <p class="text-sm text-text-primary">Showing {{ demos.length }} of {{ numOfDemos }} demos</p>
      <button
        class="items-center px-3 py-2 border border-transparent shadow-sm text-xs rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click="loadMoreDemos"
      >
        Load More
      </button>
    </div>
    <ModalWindow :open="showDemoModal" @toggleModal="toggleDemoModal">
      <DemoForm />
    </ModalWindow>
    <ModalWindow :open="showCopyModal" @toggleModal="toggleCopyModal">
      <CopyToTeam v-bind:selectedDemos="selectedDemos" @toggleModal="toggleCopyModal" />
    </ModalWindow>
    <ModalWindow :open="showDeleteModal" @toggleModal="toggleDeleteModal">
      <DeleteDemo v-bind:selectedDemos="selectedDemos" @toggleModal="toggleDeleteModal" @deleteDemos="reloadDemos" />
    </ModalWindow>
  </Dashboard>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DemoRow from "../../components/Restrat/Demos/DemoRow.vue";
import { objSort } from "../../utils/array_utils";
import MobileDemoRow from "../../components/Restrat/Demos/MobileDemoRow.vue";
import { useHead } from '@unhead/vue'

export default {
  data: function data() {
    return {
      demos: [],
      numOfDemos: 0,
      selectedDemos: [],
      allSelected: false,
      showDemoModal: false,
      showCopyModal: false,
      showDeleteModal: false,
      isCaptain: false,
      sortObject: "-id",
      page: 1,
    };
  },
  components: {
    DemoRow,
  },
  head: {
    title: 'Refrag | Demos',
  },
  mounted: function mounted() {
    if(this.user.access_level < 2) {
      this.$router.push('/demo_viewer_upgrade');
      return;
    }
    this.loadDemos();
    this.isCaptain = this.activeTeam.captain_id === this.user.id;
  },
  methods: {
    loadDemos() {
      axios({
        url: `demos?page=${this.page}`,
        headers: {
          "X-TEAM-ID": this.activeTeam.id,
        },
      }).then((res) => {
        if(this.demos.length === 0) {
          this.demos = res.data;
        } else {
          this.demos = this.demos.concat(res.data);
        }
      });
    },
    reloadDemos() {
      this.demos = [];
      this.page = 1;
      this.loadDemos();
    },
    loadMoreDemos() {
      this.page = this.page + 1;
      this.loadDemos();
    },
    toggleDemoModal: function toggleDemoModal() {
      this.showDemoModal = !this.showDemoModal;
    },
    toggleCopyModal: function toggleCopyModal() {
      this.showCopyModal = !this.showCopyModal;
    },
    toggleDeleteModal: function toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    setSortingKey: function setSortingKey(key) {
      this.sortObject = this.sortObject === `-${key}` ? key : `-${key}`;
    },
    onUpdateAllSelected(newValue) {
      this.allSelected = newValue;
    },
    onPushSelectedDemo(demo) {
      this.selectedDemos.push(demo)
    },
    onRemoveSelectedDemo(id) {
      this.selectedDemos = this.selectedDemos.filter(e => e.id !== id)
    },
  },
  computed: {
    ...mapState(["activeTeam"]),
    sortedDemos() {
      const sortDemos = this.demos;
      this.numOfDemos = sortDemos.length;
      return sortDemos.sort(objSort(this.sortObject));
    },
    isDemoSelected() {
      if (this.selectedDemos.length > 0) {
        return true;
      }
      return false;
    },
    user() {
      return this.$auth.user() || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.add-demo {
  float: right;
}
.text-centered {
  text-align: center;
}
.team-info {
  display: flex;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 24px !important;
  }
}
.sortableTitle {
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #e6e6e6 !important;
  }
}
</style>
