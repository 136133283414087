<template>
  <div v-if="props.show" class="w-full h-full fixed inset-y-0 bg-black/60 z-50 top-0 left-0 flex items-center justify-center">
    <div v-if="user.access_level < 1" class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
          <div class="rounded-full h-8 w-8 bg-gray-800 flex items-center justify-center">
              <img src="../assets/icons/lock.svg">
          </div>
          <h1 class="text-text-primary text-xl font-bold flex-1">Subscribe to start a Refrag Server</h1>
          <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>
      <iframe class="w-full aspect-[16/9] rounded-sm"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          src="https://www.youtube.com/embed/QwhgL4SsEfY?si=0qq30030bXs88N5C&autoplay=1&mute=1">

      </iframe>
      <div class="text-text-secondary">
          By subscribing to Refrag, you gain access to many different Tools!
      </div>
      <ul class="flex flex-col gap-4 text-text-secondary">
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              Starting from <span class="text-text-primary px-1">$5.4</span> / month
          </li>
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              On Demand Servers
          </li>
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              Community Content for your training needs
          </li>
      </ul>
      <router-link to="upgrade" class="bg-primary-500 px-4 py-3 hover:bg-primary-400 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md">
          Upgrade Now
          <img class="brightness-200" src="../assets/icons/rightarrow.svg">
      </router-link>
    </div>
    <div v-else-if="userActiveServer?.id && !activeServer?.id" class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
        <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
          <img src="../assets/icons/bigserver.svg" class="h-5 w-5"/>
        </div>
        <h1 class="text-text-primary text-2xl font-semibold flex-1">{{ capitalizeFirstLetter(props.server.mode) }} Setup</h1>
        <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>

      <div class="text-text-secondary text-sm leading-6">
        You are about to launch {{ props.server.mode }} mode. Select the map you want your server to load this mod on.
      </div>

      <div class="flex flex-col gap-3">
        <h2 class="text-text-primary text-sm">Map</h2>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <h2 class="flex-1 text-left font-light items-center flex gap-2">
              {{ selectedMap?.friendly_name }}
            </h2>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full max-h-48 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="map"
                  v-for="map in maps"
                  v-slot="{ active }"
                  @click="updateMap(map)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start gap-2',
                    ]"
                  >
                    {{ map.friendly_name }}
                    <CheckIcon v-if="map.name === selectedMap?.name" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <button @click="launchMode" class="bg-primary-600 hover:bg-primary-500 duration-300 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md px-4 py-3">
        Launch {{ capitalizeFirstLetter(props.server.mode) }}
        <img v-if="!modLoading" src="../assets/icons/play.svg" class="h-4 w-4 brightness-200"/>
        <div
          v-else
          class="inline-flex h-8 w-8 items-centerborder border-transparent rounded-md text-sm font-medium text-white focus:outline-none focus:ring-0 focus:ring-offset-0 flex justify-center items-center"
        >
          <svg
            class="animate-spin h-full font-normal w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </button>
    </div>
    <div v-else-if="props.showMapSelector" class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
        <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
          <img src="../assets/icons/bigserver.svg" class="h-5 w-5"/>
        </div>
        <h1 class="text-text-primary text-2xl font-semibold flex-1">{{ capitalizeFirstLetter(props.server.mode) }} Setup</h1>
        <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>

      <div class="text-text-secondary text-sm leading-6">
        You are about to start {{ props.server.mode }} mode. Set the location and map you want to play.
      </div>

      <div class="flex flex-col gap-3">
        <h2 class="text-text-primary text-sm">Location</h2>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light items-center flex gap-2">
              <flag :rounded="true" :iso="selectedLocation?.flag || storedLocationParsed?.flag" class="rounded-full h-4 w-4"/>
              {{ selectedLocation?.name || storedLocationParsed?.name }} <span class="text-text-disabled">•</span> {{ selectedLocation?.ms || storedLocationParsed?.ms }}ms
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full max-h-48 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="server"
                  v-for="server in getServers()"
                  v-slot="{ active }"
                  @click="updateSelectedLocation(server)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start gap-2',
                    ]"
                  >
                    <flag :rounded="true" :iso="server.flag" class="rounded-full h-4 w-4"/>
                    {{ server?.name }} <span class="text-text-disabled">•</span> {{ server?.ms }}ms
                    <CheckIcon v-if="server?.name === selectedLocation?.name" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div class="flex flex-col gap-3">
        <h2 class="text-text-primary text-sm">Map</h2>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <h2 class="flex-1 text-left font-light items-center flex gap-2">
              {{ selectedMap?.friendly_name }}
            </h2>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full max-h-48 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="map"
                  v-for="map in maps"
                  v-slot="{ active }"
                  @click="updateMap(map)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start gap-2',
                    ]"
                  >
                    {{ map.friendly_name }}
                    <CheckIcon v-if="map.name === selectedMap?.name" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <button @click="startServer" class="bg-primary-600 hover:bg-primary-500 duration-300 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md px-4 py-3">
        Start Server
        <img src="../assets/icons/play.svg" class="h-4 w-4 brightness-200"/>
      </button>
    </div>
    <div v-else-if="!location" class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
        <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
          <img src="../assets/icons/bigserver.svg" class="h-5 w-5"/>
        </div>
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Server Setup</h1>
        <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>

      <div class="flex flex-col gap-3">
        <h2 class="text-text-primary text-sm">Location</h2>
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full disabled:bg-gray-400 inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light items-center flex gap-2">
              <flag :rounded="true" :iso="selectedLocation.flag" class="rounded-full h-4 w-4"/>
              {{ selectedLocation?.name }} <span class="text-text-disabled">•</span> {{ selectedLocation?.ms }}ms
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full max-h-48 overflow-y-auto origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="server"
                  v-for="server in servers"
                  v-slot="{ active }"
                  @click="updateSelectedLocation(server)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start gap-2',
                    ]"
                  >
                    <flag :rounded="true" :iso="server.flag" class="rounded-full h-4 w-4"/>
                    {{ server?.name }} <span class="text-text-disabled">•</span> {{ server?.ms }}ms
                    <CheckIcon v-if="server?.name === selectedLocation.name" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="text-text-secondary text-sm leading-6">
        You're about to start the server with the predefined set you selected. <br/><br/>

        After clicking the 'Start Server' button, you will see the server details and have the ability to join and start your training.
      </div>
      <button @click="startServer" class="bg-primary-600 hover:bg-primary-500 duration-300 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md px-4 py-3">
        Start Server
        <img src="../assets/icons/play.svg" class="h-4 w-4 brightness-200"/>
      </button>
    </div>
    <div v-else-if="activeServer?.status !== 'online'" class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col">
      <div class="flex gap-3 pb-6 items-center">
        <!-- spinner -->
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" class="animate-spin">
          <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2"/>
          <path d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16" stroke="#4E54C8" stroke-width="2"/>
        </svg>
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Loading Server...</h1>
        <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>
      <div class="w-full pt-6 border-t border-gray-800 flex-col gap-6">
        <h1 class="text-text-primary font-medium pb-6">Details</h1>
        <div class="grid grid-cols-2 gap-6">
          <div class="text-text-secondary">Location</div>
          <div class="flex gap-2 justify-end items-center">
            <flag v-if="location?.flag" :rounded="true" :iso="location?.flag" class="rounded-full h-4 w-4"/>
            <div class="text-text-primary text-right">{{ location?.name }}</div>
            <div v-if="location?.ms" class="flex gap-2 items-center">
              <span class="text-text-secondary text-sm">•</span>
              <div class="text-text-primary text-right">{{ location?.ms }}ms</div>
            </div>
          </div>
          <div v-if="props.server.cs_map" class="text-text-secondary">Map</div>
          <div v-if="props.server.cs_map" class="flex gap-2 justify-end items-center">
            <img :src="`/maps/map_icon_${props.server?.cs_map?.name}.svg`" class="h-4 w-4"/>
            <div class="text-text-primary text-right">{{ props.server?.cs_map?.friendly_name }}</div>
          </div>
          <div class="text-text-secondary">Mode</div>
          <div class="text-text-primary text-right">{{ props.server?.mode }}</div>
          <div v-if="props.server.content?.id" class="text-text-secondary">Set</div>
          <div v-else class="text-text-secondary">Content</div>
          <div class="text-text-primary text-right">{{ props.server?.content?.title || props.server?.content?.name }}</div>
        </div>
      </div>
    </div>
    <div v-else class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col">
      <div class="flex gap-3 pb-6 items-center">
        <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
          <img src="../assets/icons/bigserver.svg" class="h-5 w-5"/>
        </div>
        <h1 class="text-text-primary text-2xl font-semibold flex-1">Server is Ready!</h1>
        <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>
      <div class="w-full pt-6 border-t border-gray-800 flex-col gap-6">
        <h1 class="text-text-primary font-medium pb-6">Details</h1>
        <div class="grid grid-cols-2 gap-6 pb-6">
          <div class="text-text-secondary">Location</div>
          <div class="flex gap-2 justify-end items-center">
            <flag v-if="location?.flag" :rounded="true" :iso="location?.flag" class="rounded-full h-4 w-4"/>
            <div class="text-text-primary text-right">{{ location?.name }}</div>
            <div v-if="location?.ms" class="flex gap-2 items-center">
              <span class="text-text-secondary text-sm">•</span>
              <div class="text-text-primary text-right">{{ location?.ms }}ms</div>
            </div>
          </div>
          <div class="text-text-secondary">Map</div>
          <div class="flex gap-2 justify-end items-center">
            <img :src="`/maps/map_icon_${props.server?.cs_map?.name}.svg`" class="h-4 w-4"/>
            <div class="text-text-primary text-right">{{ props.server?.cs_map?.friendly_name }}</div>
          </div>
          <div class="text-text-secondary">Mode</div>
          <div class="text-text-primary text-right">{{ props.server?.mode }}</div>
          <div class="text-text-secondary">Set</div>
          <div class="text-text-primary text-right">{{ props.server?.content?.title || props.server?.content?.name }}</div>
        </div>

        <div class="w-full py-6 border-y border-gray-800">
          <div class="w-full bg-gray-800 px-2 py-1.5 rounded-lg flex gap-2 border border-gray-700 items-center cursor-pointer duration-300"
            :class="copySucceededVal ? 'border-green-500' : 'border-gray-700'"
            v-clipboard:copy="`connect ${activeServer.ip}:${activeServer.port}; password ${activeServer.password}`"
            v-clipboard:success="copySucceeded"
          >
            <h1 class="text-text-secondary font-light text-sm flex-1">connect {{ activeServer.ip }}:{{ activeServer.port }}; password {{ activeServer.password }}</h1>
            <img src="../assets/icons/copy.svg" class="h-4 w-4 brightness-75"/>
          </div>
        </div>

        <a :href="`steam://connect/${activeServer.raw_ip}:${activeServer.port}/${activeServer.password}`" class="mt-6 flex gap-2 bg-primary-600 hover:bg-primary-500 duration-300 cursor-pointer w-full py-3 rounded-lg items-center justify-center">
          <img src="../assets/icons/play.svg" class="h-4 w-4 brightness-200"/>
          <h1 class="text-text-primary">Join</h1>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, getCurrentInstance, computed, onBeforeUnmount } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { useStore } from "vuex";
import axios from "axios";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import {
  XMarkIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/vue/24/outline";
import { min } from 'moment/moment';

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  },
  server: {
    type: Object,
    required: true
  },
  showMapSelector: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["toggleModal"]);
const store = useStore();
const websocket = ref(null);
const subscription = ref(null);
const location = ref(null);
const activeServer = ref(null);
const userActiveServer = computed(() => store.state.activeServer);
const instance = getCurrentInstance();
const copySucceededVal = ref(false);
const auth = useAuth();
const user = ref(auth.user());
const storedLocation = computed(() => localStorage.getItem('activeLocation'));
const selectedLocation = ref(null);
const servers = computed(() => store.state.servers?.sort((a, b) => a.ms - b.ms)) || null;
const selectedMap = ref(null);
const modLoading = ref(false);

function copySucceeded() {
  copySucceededVal.value = true;
  store.commit("showMessage", {messageType: "success", messageText: "Link Copied Successfully"})
  setTimeout(() => {
    copySucceededVal.value = false;
    store.commit("hideMessage");
  }, 3000);
}

function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

function getServers() {
  if (!servers.value) {
    axios({
      method: "get",
      url: "/server_locations/",
      headers: {
        "X-TEAM-ID": store.state.activeTeam.id,
      },
    })
    .then(({ data }) => {
      servers.value = data;
      store.commit("setServers", data);
      return data;
    })
  }

  return servers.value;
}

const maps = computed(() => {
  return store.state.modInfo?.mods?.find(mod =>
    mod?.name?.toLowerCase() === props.server?.mode?.toLowerCase()
  )?.cs_maps?.filter(map =>
    map.cs2 === true
  )?.sort((a, b) =>
    a.friendly_name?.toLowerCase().localeCompare(b.friendly_name?.toLowerCase())
  );
});

const storedLocationParsed = computed(() =>
  localStorage.getItem('activeLocation')
    ? JSON.parse(localStorage.getItem('activeLocation'))
    : null
);

function closeModal() {
  emit("close");
}

function reportError() {
  emit("failed")
}

function reportSuccess() {
  emit("success")
}

function getServerLocation() {
  if (storedLocation.value) {
    location.value = JSON.parse(storedLocation.value);
  } else if (servers.value?.length > 0) {
    selectedLocation.value = store.state.servers[0];
  } else {
    axios({
      method: "get",
      url: "/server_locations/",
      headers: {
        "X-TEAM-ID": store.state.activeTeam.id,
      },
    })
    .then(({ data }) => {
      selectedLocation.value = data[0]
    })
  }
}

function updateSelectedLocation(server) {
  selectedLocation.value = server;
  location.value = server;
}

function updateMap(map) {
  selectedMap.value = map;
  props.server.cs_map = map;
}

function launchMode() {
  modLoading.value = true;
  props.showMapSelector = false;
  axios({
    url: `/cs_servers/${userActiveServer.value.id}/exec_server_command`,
    method: "POST",
    params: {
      command: "change_map_and_mod",
      value: `${selectedMap.value.name}, ${props.server.mode}`,
    },
    headers: {
      "X-TEAM-ID": store.getters.activeTeam.id,
    },
  })
    .then(() => {
      activeServer.value = userActiveServer.value;
      modLoading.value = false;
    })
    .catch((err) => {
      modLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: "Could not communicate with server",
      });
      setTimeout(() => {
        store.commit("hideMessage");
      }, 3000);
    });
}

function startServer() {
  // if server is active, just launch the mode for the selected map
  if (userActiveServer.value?.id) {
    if (props.server.cs_map) {
      updateMap(props.server.cs_map);
      launchMode();
      return;
    }

    updateMap(maps.value[0])
    props.showMapSelector = true;
    return;
  }
  props.showMapSelector = false;

  if (!location.value && selectedLocation.value) {
    location.value = selectedLocation.value;
    localStorage.setItem('activeLocation', JSON.stringify(selectedLocation.value));
  }
  axios({
    url: "/cs_servers/start_new_server",
    method: "POST",
    timeout: 120000,
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
    data: {
      server_location_id: location.value.id,
      game: 'cs2',
      betaServer: false,
      secureServer: false,
      is_assessment: false,
      launch_settings: {
        mod: getMod(props?.server?.mode) || null,
        map: props?.server?.cs_map?.name || null,
        subject_id: props?.server?.content?.id || null,
        subject_type: getSubjectType(props?.server?.mode) || null,
        task_list_id: props?.server?.content?.task_list_id || props?.server?.content?.id || null,
        task_list_section_id: props?.server?.content?.task_list_section_id || null,
        task_list_difficulty: props?.server?.content?.task_list_difficulty || null,
        time_to_kill: props?.server?.time_to_kill || null,
        is_exam: props?.server?.content?.is_exam || false,
      },
    },
  })
    .then(({ data }) => {
      store.commit("setActiveServer", data);
      activeServer.value = data;
      reportSuccess();
      subscribeToServer();
    })
    .catch((err) => {
      console.log(err);
      reportError();
    });
}

function subscribeToServer() {
  const cable = instance.appContext.config.globalProperties.$cable;

  if (!websocket.value) {
    websocket.value = cable.connect();
  }

  if (!subscription.value) {
    subscription.value = websocket.value.subscriptions.create(
      { channel: "CsServerChannel", id: activeServer.value.id },
      {
        received: (data) => {
          activeServer.value = data;
          store.commit("setActiveServer", data);
        },
      }
    );
  }

}

function getMod(mode) {
  switch (mode) {
    case "Creator Studio":
      return "creatorstudio";
    case "VPL":
      return "vpl";
    case "Grenade Groups":
      return "nadr";
    case "Strategies":
      return "nadr";
    default:
      return mode;
  }
}

function getSubjectType(mode) {
  if (props.server.content?.type) {
    return props.server.content?.type;
  }

  switch (mode) {
    case "Creator Studio":
      return "custom_set";
    case "Grenade Groups":
      return "grenades";
    case "Strategies":
      return "strat";
    case "VPL":
      return "strat";
    default:
      return null;
  }
}

onBeforeUnmount(() => {
  if (subscription.value) {
    subscription.value.unsubscribe();
    subscription.value = null;
  }
  if (websocket.value) {
    websocket.value.disconnect();
    websocket.value = null;
  }
});

getServerLocation();

watch(() => props.show, () => {
  if (props.show && !activeServer.value?.id && location.value && user.value.access_level > 0 && storedLocation.value && !props.showMapSelector) {
    startServer()
  }
})

watch(() => location.value, () => {
  if (location.value && !activeServer.value?.id && props.show && user.value.access_level > 0 && storedLocation.value && !props.showMapSelector) {
    startServer()
  }
})

watch(() => maps.value, () => {
  if (selectedMap.value === null && !props.server.cs_map?.name) {
    updateMap(maps.value[0]);
  }
  if (selectedMap.value === null && props.server.cs_map?.name) {
    const chosenMap = maps.value.find(map => map.name === props.server.cs_map.name)
    if (chosenMap) {
      updateMap(chosenMap);
    }
  }
})


</script>

