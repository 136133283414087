<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/24/outline';
</script>
<template>
	<form class="divide-y">
      <div>
        <h3 class="text-lg leading-6 font-medium text-white">Copy your demo to another team</h3>
        <p class="mt-1 text-sm text-gray-500">
          Please fill out the form and click "Copy"
        </p>
      </div>
      <div class="mt-6 pt-2 grid grid-cols-1 gap-y-6">
        <div>
          <label for="username" class="block text-sm font-medium text-white">
            Demos selected
          </label>
          <div class="mt-2 flow-root">
            <ul role="list" class="divide-y divide-gray-200 px-4">
              <li v-for="demo in selectedDemos" :key="demo.id" class="py-4">
                <div class="flex items-center space-x-4">
                  <div class="min-w-0 flex-1">
                    <p class="truncate text-sm font-xs text-white">{{ demo.name }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="py-3">
        <label for="username" class="block text-sm font-medium text-white">
            Team
        </label>
        <Menu as="div" class="pt-2 relative inline-block m-auto w-full">
          <div>
            <MenuButton
              class="group w-full rounded-md bg-gray-900 px-3.5 py-2 text-center text-sm font-medium text-white hover:outline hover:outline-gray-500 focus:outline-outline focus:outline-gray-500 "
            >
              <span class="flex w-full items-center justify-between">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <span class="flex min-w-0 flex-1 flex-col">
                    <span class="truncate text-sm font-medium text-white">{{
                      selectedTeam.name || "Select a team to send the demos to"
                    }}</span>
                  </span>
                </span>
                <ChevronUpDownIcon
                  class="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 left-0 z-50 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1">
                <MenuItem v-for="team in user.teams" :key="team.id"> 
                  <a
                    href="#"
                    v-show="team.id !== activeTeam.id"
                    @click="updateSelectedTeam(team)"
                    :class="[
                      activeTeam.id == team.id
                        ? 'bg-gray-100 text-white'
                        : 'text-white',
                      'block px-4 py-2 text-sm',
                    ]"
                    >{{ team.name }}</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
			<div class="pt-5">
        <button
          type="button"
          class="ml-3 float-right inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          @click="cancel"
          >
          Cancel
        </button>
				<button
					type="button"
					class="ml-3 float-right inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					@click="copyDemos"
					>
					Copy
				</button>
			</div>
    </form>
  </template>
  
  <script>
  import { mapMutations, mapState } from "vuex";
	import axios from "axios";

  export default {
    data: function data() {
        return {
            selectedTeam: {},
						selectedDemoIds: [],
        }
    },
    props: ['selectedDemos'],
		mounted: function mounted() {
			this.selectedDemos.forEach(demo => {
        this.selectedDemoIds.push(demo.id)
      });
		},
    methods: {
			...mapMutations(["showMessage", "hideMessage"]),
			updateSelectedTeam(team) {
					this.selectedTeam = team;
			},
			copyDemos(e) {
					e.preventDefault();
					if (this.selectedTeam.id !== undefined) {
            axios({
              url: "/demos/copy_to_team",
              method: "POST",
              data: {
                demo_ids: this.selectedDemoIds,
                team_id: this.selectedTeam.id
              }
            })
            this.showMessage({ messageType: "success", messageText: `The demos were copied over to team ${this.selectedTeam.name}` });
						this.$emit('toggleModal');
					} else {
						this.showMessage({ messageType: "error", messageText: `Please select a team!` });
					}
			  },
      cancel(e) {
        e.preventDefault();
        this.$emit('toggleModal');
      },			
    },
    computed: {
			...mapState(["activeTeam"]),
			user() {
					return this.$auth.user() || {};
			},
    }
  };
  </script>
  