<template>
  <div>
    <form>
      <div>
        <h3 class="text-lg leading-6 font-medium text-white">Upload your demo</h3>
        <p class="mt-1 text-sm text-gray-300">
          Please fill out the form and click "Save"
        </p>
      </div>
      <div class="mt-6 grid grid-cols-1 gap-y-6">
        <div>
          <label for="username" class="block text-sm font-medium text-white">
            Demo file
          </label>
          <div class="mt-1 flex rounded-md">
            <div class="mt-1 sm:mt-0 sm:col-span-2 w-full">
              <div
                class="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                :class="{
                  'border-green-500': demoFile && !validator.demoFile.$error,
                  'border-red-400': demoFile && validator.demoFile.$error,
                }"
                @dragover="dragover"
                @dragleave="dragleave"
                @drop="drop"
              >
                <div class="space-y-1 text-center">
                  <div v-if="!demoFile">
                    <svg
                      class="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="flex text-sm text-gray-300">
                      <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md font-medium text-blue-600 hover:text-blue-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          class="sr-only"
                          type="file"
                          name="demoFile"
                          accept=".dem"
                          @change="processFile($event)"
                          ref="file"
                        />
                      </label>
                      <p class="pl-1">or drag and drop</p>
                    </div>
                    <p class="text-xs text-gray-300">DEM files</p>
                  </div>
                  <div
                    class="flex flex-row justify-between items-center text-text-primary"
                    v-else
                  >
                    <p>{{ demoFile.name }}</p>
                    <XCircleIcon
                      class="h-5 w-5 text-gray-400 cursor-pointer"
                      aria-hidden="true"
                      @click="resetDemo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p
            class="mt-4 text-center text-sm text-red-400"
            v-if="demoFile && validator.demoFile.$error"
          >
            Invalid demo file, please make sure to only upload the .dem file.
          </p>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex justify-end">
          <div
            class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
            v-if="uploadPercentage && uploadPercentage < 100"
          >
            <div
              class="bg-blue-600 h-2.5 rounded-full"
              :style="{ width: `${uploadPercentage}%` }"
            ></div>
          </div>
          <div class="processing" v-if="loading && uploadPercentage == 100">
            <div class="content">
              <p>Processing...</p>
            </div>
          </div>
          <button
            type="button"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            :class="[!saveEnabled && 'bg-gray-400 hover:bg-gray-400']"
            :disabled="!saveEnabled"
            @click="create"
            v-if="!loading && uploadPercentage != 100"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "@vuelidate/validators";
import { XCircleIcon } from "@heroicons/vue/20/solid";
import { mapState } from "vuex";
import { useVuelidate } from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "demo_form",
  components: {
    XCircleIcon,
  },
  data() {
    return {
      demoFile: null,
      uploadPercentage: null,
      loading: false,
      demoId: null,
      demoInfo: {},
      demoIsDuplicate: false,
      validator: useVuelidate(),
    };
  },
  methods: {
    resetDemo() {
      this.demoFile = null;
    },
    create: function create() {
      if (this.loading) return;
      if (!this.saveEnabled) return;

      const { demoFile } = this;
      const formData = new FormData();
      this.loading = true;
      formData.append("demo[game]", "cs2");
      formData.append("demo_info", JSON.stringify(this.demoInfo));


      axios
        .post("demos.json", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
        .then((res) => {
          if (res.data.status == "created") {
            this.uploadFile(demoFile, res.data.demo_upload_url);
          } else {
            this.$router.push({
              name: "Demos",
            });
          }
          this.demoId = res.data.id;
        });
    },
    uploadFile(file, url) {
      axios
        .put(url, file, {
          onUploadProgress: function onUploadProgress(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              10
            );
          }.bind(this),
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
        .then(() => {
          this.finishCreate();
        });
    },
    finishCreate() {
      axios
        .post(
          `demos/${this.demoId}/analyze`,
          {},
          {
            headers: {
              "X-TEAM-ID": this.activeTeam.id,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.$router.push({
            name: "ReviewPage",
            params: { id: this.demoId },
          });
        });
    },
    processFile(event) {
      console.log("Processing file");
      this.setDemoFile(event.target.files);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-50")) {
        event.currentTarget.classList.add("bg-green-50");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove("bg-green-50");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.setDemoFile(event.dataTransfer.files);
      event.currentTarget.classList.remove("bg-green-50");
    },
    setDemoFile(files) {
      console.log("Setting file");
      [this.demoFile] = files;
      this.validator.demoFile.$touch();
    },
  },
  computed: {
    ...mapState(["activeTeam"]),
    saveEnabled() {
      return this.demoFile && !this.validator.demoFile.$error;
    },
  },
  validations: {
    demoFile: {
      required,
      mustBeDemo: (demoFile) => {
        console.log("Validating demo");
        if (!demoFile) return false;
        return demoFile.name.endsWith(".dem");
      },
    },
    name: { required },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
}
</style>
