<template>
  <TransitionRoot as="template" :show="showVideoModal">
    <Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto w-full h-full flex justify-center items-center">
      <div class="relative w-full h-full bg-gray-500 opacity-75" @click="emit('toggleModal')">

      </div>

      <div class="absolute flex flex-col justify-start bg-gray-900 p-2 w-1/2">
        <div class="w-full text-white text-lg font-semibold pt-2 pl-2 pb-4">
          {{ props.video.grenade_video.title }}
        </div>
        <h2 class="text-white text-sm font-light px-2 pb-4">{{ props.video.grenade_video.description }}</h2>
        <iframe width="100%" height="" class="aspect-video px-2 pb-4"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          :src="props.video.grenade_video.video_url">
        </iframe>

        
        <XMarkIcon class="absolute right-2 top-2 cursor-pointer h-5 w-5 text-white z-20" @click="emit('toggleModal')" aria-hidden="true" />

      </div>

      

    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { watch, ref, defineEmits } from "vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";


const emit = defineEmits(['toggleModal'])
const props = defineProps({
  video: {
    type: Object
  },
  showVideoModal: {
    type: Boolean
  }
})

</script>
<style lang="scss" scoped>
.aspect-video {
  aspect-ratio: 16 / 9;
}
</style>