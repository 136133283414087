const railsErrorToMessage = (error) => {
    console.log(error.response.data);
    const message = error.response.data;
    if (typeof message === 'string') {
        return message;
    }

    // Handle ActiveRecord-style validation errors object
    return Object.entries(message)
        .map(([field, errors]) => {
            return `${field.split('_').join(' ').charAt(0).toUpperCase() + field.split('_').join(' ').slice(1)} ${errors.join(', ')}`;
        })
        .join('. ');
}

export { railsErrorToMessage };
