<template>
  <Dashboard v-if="user.access_level < 1" title="Bootcamp" no-padding="true">
    <!-- show screenshot and push to upgrade -->
    <div class="w-full h-full bg-gray-950 relative flex justify-center items-center">
      <img
        class="select-none w-full h-full object-cover"
        src="../../assets/routines-preview.png"
      />
      <div
        class="w-full h-full bg-black/60 z-50 absolute items-center justify-center flex"
      >
        <UpgradeModal
          title="Upgrade to access Refrag Routines"
          tier="Player"
          firstBenefit="Refrag Academy"
          secondBenefit="Refrag Bootcamp"
          primaryPromotionType="bootcamp"
          video_url="https://www.youtube.com/embed/dC_uuBEZ9CI?si=fj-nBsQhEyA6asV2?autoplay=1"
        >
        </UpgradeModal>
      </div>
    </div>
  </Dashboard>
  <Dashboard v-else title="Bootcamps">
    <div v-if="loading" class="flex justify-center py-10">
      <svg class="w-16 h-16 text-white animate-spin" fill="none" viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          fill="currentColor"
        ></path>
      </svg>
    </div>
    <div v-else class="flex flex-col gap-8 w-full">
      <div class="flex gap-10 items-center">
        <div class="flex flex-col gap-3 flex-1">
          <h1 class="text-4xl font-medium text-text-primary">Routines</h1>
          <p class="text-text-secondary text-sm font-light">
            Collection of routines prepared by pro players and the community.
          </p>
        </div>
        <div
          @click="createRoutine()"
          class="px-3 py-2.5 bg-primary-600 rounded-lg hover:bg-primary-500 duration-300 text-text-primary text-xs font-medium cursor-pointer"
        >
          Create Routine
        </div>
      </div>

      <div v-if="myRoutines?.length > 0" class="flex flex-col gap-4">
        <h1 class="text-2xl font-medium text-text-primary">My Routines</h1>
        <div
          class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6"
        >
          <div
            v-for="routine in myRoutines"
            class="bg-gray-900 rounded-lg w-full flex flex-col overflow-hidden divide-y divide-gray-800"
          >
            <div class="flex gap-3 items-center p-4">
              <div class="flex flex-col gap-2 flex-1">
                <h1 class="text-text-primary">{{ routine.name }}</h1>
                <p class="text-text-secondary text-xs">
                  Created {{ moment(routine.created_at).format("MMM Do YYYY") }}
                </p>
              </div>
              <div v-if="routine.status === 'published'" class="flex gap-2 items-center">
                <div
                  v-if="!routine.coach_curated"
                  @click="
                    router.push({
                      name: 'RoutineEditPage',
                      params: { slug: routine.slug },
                    })
                  "
                  class="cursor-pointer h-8 w-8 rounded-md bg-gray-800 flex items-center justify-center hover:bg-gray-700 duration-300"
                >
                  <PencilSquareIcon class="h-5 w-5 text-text-primary" />
                </div>
                <div
                  @click="launchRoutine(routine)"
                  class="cursor-pointer h-8 w-8 rounded-md bg-primary-600 flex items-center justify-center hover:bg-primary-500 duration-300"
                >
                  <svg
                    v-if="launchLoading === routine.id"
                    class="animate-spin h-full font-normal w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <HandThumbUpIcon
                    v-else-if="launchSuccess === routine.id"
                    class="text-green-500 w-4 h-4"
                  ></HandThumbUpIcon>
                  <img v-else class="brightness-200" src="../../assets/icons/play.svg" />
                </div>
              </div>

              <div v-else class="flex gap-2 items-center">
                <div
                  @click="toggleDeleteRoutineModal(routine)"
                  class="cursor-pointer h-8 w-8 rounded-md bg-gray-800 flex items-center justify-center hover:bg-gray-700 duration-300"
                >
                  <img class="brightness-200" src="../../assets/icons/trash.svg" />
                </div>
                <div
                  v-if="!routine.coach_curated"
                  @click="
                    router.push({
                      name: 'RoutineEditPage',
                      params: { slug: routine.slug },
                    })
                  "
                  class="cursor-pointer h-8 w-8 rounded-md bg-primary-600 flex items-center justify-center hover:bg-primary-500 duration-300"
                >
                  <PencilSquareIcon class="h-5 w-5 text-text-primary" />
                </div>
              </div>
            </div>
            <div class="flex justify-between w-full p-4">
              <div class="flex flex-col gap-2">
                <h1
                  class="text-text-secondary text-[10px] tracking-[1.5px] leading-[20px] uppercase"
                >
                  Author
                </h1>
                <div class="flex gap-1 items-center">
                  <img
                    v-if="user.steam_profile?.avatarfull"
                    :src="user.steam_profile?.avatarfull"
                    class="w-4 h-4 rounded-full"
                  />
                  <h1 class="text-text-primary text-xs">{{ username() }}</h1>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <h1
                  class="text-text-secondary text-[10px] tracking-[1.5px] leading-[20px] uppercase"
                >
                  Status
                </h1>
                <div class="flex gap-1 items-center">
                  <h1 class="text-text-primary text-xs uppercase">
                    {{ routine.status }}
                  </h1>
                </div>
              </div>
              <div class="flex flex-col gap-2">
                <h1
                  class="text-text-secondary text-[10px] tracking-[1.5px] leading-[20px] uppercase"
                >
                  Length
                </h1>
                <div class="flex gap-1 items-center">
                  <h1 class="text-text-primary text-xs">
                    {{ routine.total_time_estimation || 0 }}min
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row gap-4">
        <div v-if="coachRoutines?.length > 0" class="flex flex-col gap-4 flex-1">
          <h1 class="text-2xl font-medium text-text-primary">Coach Routines</h1>
          <CoachRoutine :routines="coachRoutines" @launchRoutine="launchRoutine" />
        </div>
        <div
          v-if="proRoutines?.length > 0"
          class="flex flex-col gap-4"
          :class="{
            'flex-3': coachRoutines?.length > 0,
            'flex-1': coachRoutines?.length === 0,
          }"
        >
          <h1 class="text-2xl font-medium text-text-primary">Official Pro Routines</h1>
          <div
            class="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6"
            :class="{
              'xl:grid-cols-2': coachRoutines?.length > 0,
              '2xl:grid-cols-3': coachRoutines?.length > 0,
              'md:grid-cols-1': coachRoutines?.length > 0,
            }"
          >
            <div
              v-for="routine in proRoutines.slice(0, windowWidth >= 1280 && coachRoutines?.length > 0 ? 4 : proRoutines.length)"
              class="bg-gray-900 rounded-lg w-full flex flex-col overflow-hidden relative"
            >
              <div
                class="w-full aspect-[1.8] pro-card-gradient flex items-start justify-center overflow-hidden relative"
              >
                <img :src="routine.image_url" alt="Routine Image" class="z-10" />
              </div>
              <div
                @click="
                  router.push({ name: 'RoutinePage', params: { slug: routine.slug } })
                "
                class="bg-gray-800 rounded-lg h-6 w-6 absolute top-5 right-5 z-10 flex items-center justify-center hover:scale-105 duration-300 cursor-pointer hover:bg-gray-700"
              >
                <img class="h-4" src="../../assets/icons/rightarrow.svg" />
              </div>
              <div class="p-4 flex flex-col gap-4 divide-y divide-gray-800">
                <div class="flex flex-col gap-2">
                  <div class="flex items-center gap-2">
                    <h1 class="text-xl font-medium text-text-primary">
                      {{ routine.pro_player.name }}
                    </h1>
                    <span class="text-text-secondary">•</span>
                    <h1 class="text-xl font-medium text-text-primary">
                      {{ routine.pro_player.team_name }}
                    </h1>
                  </div>
                  <h1 class="text-text-primary">{{ routine.name }}</h1>
                </div>

                <div class="flex gap-2 items-center pt-4">
                  <button
                    :disabled="routine.enrolled"
                    @click="subscribe(routine)"
                    class="inline-flex gap-2 h-8 w-8 items-centerborder cursor-pointer border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-800 hover:bg-gray-700 duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 flex justify-center items-center"
                  >
                    <PlusCircleIcon v-if="!routine.enrolled" class="w-3 h-3" />
                    <CheckCircleIcon v-else class="w-3 h-3" />
                  </button>
                  <button
                    @click="launchRoutine(routine)"
                    class="inline-flex h-8 px-2 text-xs text-text-primary bg-primary-600 hover:bg-primary-500 duration-300 cursor-pointer border-transparent rounded-lg items-center gap-1"
                  >
                    <svg
                      v-if="launchLoading === routine.id"
                      class="animate-spin h-full font-normal w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <HandThumbUpIcon
                      v-else-if="launchSuccess === routine.id"
                      class="text-green-500 w-6 h-6"
                    ></HandThumbUpIcon>
                    <PlayIcon v-else class="w-4 h-4 text-text-primary" />
                    Launch Routine
                  </button>
                  <div class="flex-1"></div>
                  <div class="flex gap-1 items-center">
                    <ClockIcon class="w-3 h-3 text-text-secondary" />
                    <h2 class="text-text-secondary text-xs">
                      {{ routine.total_time_estimation || 0 }} min
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex gap-4">
          <h1 class="text-2xl font-medium text-text-primary flex-1">Made by Community</h1>
          <div class="flex justify-end w-full flex-1 gap-4">
            <div class="flex gap-2">
              <button
                class="relative cursor-pointer inline-flex items-center justify-center h-8 w-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                :class="
                  page == 1 ? 'bg-gray-800 opacity-30' : 'bg-gray-800 hover:bg-gray-700'
                "
                @click="previousPage"
                :disabled="page == 1"
              >
                <ChevronLeftIcon class="w-4 h-4 text-text-primary" />
              </button>
              <button
                class="relative cursor-pointer inline-flex items-center justify-center bg-gray-800 hover:bg-gray-700 h-8 w-8 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="nextPage"
              >
                <ChevronRightIcon class="w-4 h-4 text-text-primary" />
              </button>
            </div>
            <form action="" class="relative">
              <input
                type="search"
                v-model="searchInput"
                placeholder="Search..."
                class="peer cursor-pointer text-sm font-light relative z-10 h-8 w-42 rounded-md bg-gray-800 border-gray-700 focus:border-gray-700 focus:outline-none focus:ring-0 pl-8 outline-none text-text-primary placeholder:text-text-secondary"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute top-0 left-0 z-10 ml-2 translate-y-1/2 h-4 w-4 absolute"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#9CADC4"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </form>
          </div>
        </div>

        <div
          class="flex flex-col gap-1 lg:gap-[2px] rounded-lg w-full overflow-hidden"
          ref="contentBlock"
        >
          <ContentRow
            ref="contentRow"
            :content="content"
            :modUrl="'/routines'"
            :activeMod="'Routines'"
            :activeServer="activeServer"
            @updateServerInfo="updateServerInfo"
            @loadContent="loadContent"
            v-for="content in communityRoutines"
            :key="content.id"
          >
          </ContentRow>
        </div>
      </div>
    </div>
  </Dashboard>
  <ModalWindow :open="showDeleteRoutineModal">
    <div class="flex flex-col gap-6">
      <div class="flex items-center gap-3">
        <h1
          class="text-text-primary text-[24px] font-semibold flex gap-3 items-center flex-1"
        >
          <img src="../../assets/icons/error.svg" alt="Steam Icon" class="w-8 h-8" />
          Delete {{ selectedRoutine?.name }}
        </h1>
        <button
          @click="showDeleteRoutineModal = false"
          class="text-text-primary text-[24px] font-semibold cursor-pointer"
        >
          <XMarkIcon class="w-6 h-6 text-text-secondary" />
        </button>
      </div>

      <p class="text-text-secondary text-sm">
        Are you sure you want to delete this custom routine? This action cannot be undone.
      </p>
      <div class="flex gap-4">
        <button
          @click="deleteRoutine(selectedRoutine)"
          class="bg-danger-400 text-danger-950 px-4 py-2 rounded-md font-semibold hover:bg-danger-300 w-full duration-300"
        >
          Yes, remove
        </button>
        <button
          @click="showDeleteRoutineModal = false"
          class="bg-gray-800 text-text-primary px-4 py-2 rounded-md font-medium hover:bg-gray-700 w-full duration-300"
        >
          No, keep
        </button>
      </div>
    </div>
  </ModalWindow>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    @close="showServerModal = false"
  />
</template>

<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import StartServerModal from "../../components/StartServerModal.vue";
import UpgradeModal from "../../components/UpgradeModal.vue";
import ContentRow from "../../components/CommunityHub/ContentRow.vue";
import ModalWindow from "../../components/ModalWindow.vue";
import CoachRoutine from "../../components/Coach/Routine.vue";
import axios from "axios";
import moment from "moment";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import { useHead } from "@unhead/vue";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  PlayIcon,
  CheckCircleIcon,
  ClockIcon,
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/vue/20/solid";
import { Circle } from "konva/lib/shapes/Circle";

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const store = useStore();
const user = ref(auth.user());
const communityRoutines = ref([]);
const myRoutines = ref([]);
const coachRoutines = ref([]);
const activeServer = computed(() => store.getters.activeServer);
const showServerModal = ref(false);
const launchLoading = ref(false);
const launchSuccess = ref(false);
const serverInfo = ref(null);
const proRoutines = ref([]);
const loading = ref(true);
const page = ref(1);
const searchInput = ref("");
const communitySearchLoading = ref(false);
const showDeleteRoutineModal = ref(false);
const selectedRoutine = ref(null);
const totalPages = ref(0);
const windowWidth = ref(window.innerWidth);

useHead({
  title: "Refrag | Routines",
  description:
    "Play a mixture of community created and pro warmup and training routines using Refrag's routines feature.",
});

function toggleDeleteRoutineModal(routine) {
  selectedRoutine.value = routine;
  showDeleteRoutineModal.value = !showDeleteRoutineModal.value;
}

function getBootcamps() {
  loading.value = true;
  axios.get(`/task_lists?list_type=routine`).then((res) => {
    communityRoutines.value = res.data;
    loading.value = false;
  });

  axios.get(`/task_lists/my_routines?list_type=routine`).then((res) => {
    myRoutines.value = res.data;
  });

  axios.get(`/task_lists/pro_routines?list_type=routine`).then((res) => {
    proRoutines.value = res.data;
  });

  axios.get(`/task_lists/my_daily_routine`).then((res) => {
    coachRoutines.value = res.data;
  });
}

function updateServerInfo(info) {
  serverInfo.value = info;
  showServerModal.value = true;
}

function deleteRoutine(routine) {
  axios({
    method: "delete",
    url: `/task_lists/${routine.id}`,
  }).then(() => {
    getBootcamps();
    showDeleteRoutineModal.value = false;
  });
}

function getCommunityContent() {
  axios({
    method: "get",
    url: "/task_lists",
    params: {
      list_type: "routine",
      page: page.value,
      search_input: searchInput.value,
    },
  }).then((res) => {
    communityRoutines.value = res.data;
  });
}

function subscribe(routine) {
  axios({
    method: "post",
    url: `/task_lists/${routine.slug}/enroll`,
  }).then(() => {
    getBootcamps();
  });
}

function loadContent() {
  getCommunityContent();
}

function createRoutine() {
  axios({
    method: "post",
    url: "/task_lists",
  }).then((res) => {
    router.push({ name: "RoutineEditPage", params: { slug: res.data.slug } });
  });
}

function username() {
  if (user.value?.username) {
    return user.value?.username;
  }
  return user.value?.steam_profile?.personaname;
}

function launchRoutine(bootcamp) {
  if (!activeServer.value?.id) {
    serverInfo.value = {
      mode: "Creator Studio",
      content: {
        task_list_id: bootcamp.id,
        is_exam: false,
        title: bootcamp.name,
        type: "task",
      },
    };
    showServerModal.value = true;
    return;
  }

  launchLoading.value = bootcamp.id;
  axios({
    url: `/cs_servers/${activeServer.value?.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    data: {
      command: "launch_routine",
      value: `${bootcamp.id}`,
      is_client_command: false,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(() => {
      store.commit("showMessage", {
        messageType: "success",
        messageText: "Routine launched!",
      });
      launchLoading.value = false;
      launchSuccess.value = true;
      setTimeout(() => {
        launchSuccess.value = false;
        store.commit("hideMessage");
      }, 2000);
    })
    .catch((err) => {
      launchLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: `Could not execute server command!`,
      });
    });
}

function previousPage() {
  if (page.value > 1) {
    page.value--;
    getCommunityContent();
  }
}

function nextPage() {
  page.value++;
  getCommunityContent();
}

watch(
  () => searchInput.value,
  () => {
    if (communitySearchLoading.value) return;
    communitySearchLoading.value = true;

    setTimeout(() => {
      communitySearchLoading.value = false;
      page.value = 1;
      getCommunityContent();
    }, 500);
  }
);

if (user.value.access_level > 0) {
  getBootcamps();
}
</script>

<style scoped>
.pro-card-gradient {
  background: linear-gradient(180deg, #131920 0%, #1f2833 100%);
}
</style>
