<template>
  <div v-if="props.show" class="w-full h-full fixed inset-y-0 bg-black/60 z-50 top-0 left-0 flex items-center justify-center">
    <div class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
          <div class="rounded-full h-8 w-8 bg-gray-800 flex items-center justify-center">
              <img src="../assets/icons/lock.svg">
          </div>
          <h1 class="text-text-primary text-xl font-bold flex-1">Subscribe to start a Refrag Server</h1>
          <XMarkIcon @click="closeModal" class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"/>
      </div>
      <iframe class="w-full aspect-[16/9] rounded-sm"
          frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
          src="https://www.youtube.com/embed/QwhgL4SsEfY?si=0qq30030bXs88N5C&autoplay=1&mute=1">

      </iframe>
      <div class="text-text-secondary">
          By subscribing to Refrag, you gain access to many different Tools!
      </div>
      <ul class="flex flex-col gap-4 text-text-secondary">
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              Starting from <span class="text-text-primary px-1">$5.4</span> / month
          </li>
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              On Demand Servers
          </li>
          <li class="flex">
              <img class="mr-2" src="../assets/icons/check.svg">
              Community Content for your training needs
          </li>
      </ul>
      <router-link to="upgrade" class="bg-primary-500 px-4 py-3 hover:bg-primary-400 cursor-pointer text-text-primary text-semibold flex gap-2 items-center justify-center rounded-md">
          Upgrade Now
          <img class="brightness-200" src="../assets/icons/rightarrow.svg">
      </router-link>
    </div>
  </div>
</template>

<script setup>
import {
  XMarkIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/vue/24/outline";

const props = defineProps({
  show: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['closeModal']);

const closeModal = () => {
  emit('closeModal');
};
</script>