<template>
  <div
    v-if="props.show"
    class="w-full h-full fixed inset-y-0 bg-black/60 z-50 top-0 left-0 flex items-center justify-center"
  >
    <div class="w-[500px] bg-gray-900 rounded-lg p-6 flex flex-col gap-6">
      <div class="flex gap-2 items-center">
        <img class="w-6 h-6" src="../assets/icons/info-icon.svg" />
        <h1 class="text-text-primary text-xl font-bold flex-1">Update Steam authentication code</h1>
        <XMarkIcon
          @click="closeModal()"
          class="w-6 h-6 text-text-secondary hover:scale-105 duration-300 hover:text-text-primary cursor-pointer"
        />
      </div>
      <p class="text-text-secondary text-sm leading-6">
        Your code is missing. You need to update it to continue sync your matches to the
        platform. You can remove this code at any time in your <a href="/settings" class="underline">settings</a>.
      </p>

      <div class="w-full h-px bg-gray-800" />

      <div class="flex gap-2 flex-col">
        <h1 class="text-text-primary flex-1">Steam authentication code</h1>
        <p class="text-text-secondary text-sm">
          You can find the code from the Steam page
          <a
            href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128&ref=refrag.gg"
            target="_blank"
            class="underline text-text-primary cursor-pointer"
            >here</a
          >.
        </p>
        <input
          @input="steamAuthCode = $event.target.value"
          placeholder="XXXX - XXXXX - XXXX"
          class="block w-full rounded-md bg-gray-800 border-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs text-text-secondary"
          :class="[errorMessage && 'border-red-500']"
          type="text"
          :value="steamAuthCode"
        />
        <p class="text-sm text-red-400 flex gap-2 items-center" v-if="errorMessage">
          <img src="../assets/icons/error.svg" class="w-5 h-5" />
          {{ errorMessage }}
        </p>
        <button
          @click="saveSteamAuthCode"
          class="bg-primary-600 hover:bg-primary-500 duration-300 text-text-primary rounded-md px-4 py-3 w-full mt-4 disabled:opacity-50 text-center justify-center flex items-center"
          :disabled="checkFormat() || loading"
        >
          <span v-if="loading">
            <svg
              width="24"
              height="24"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="animate-spin"
            >
              <circle cx="16" cy="16" r="15" stroke="#c3c3c3" stroke-width="2" />
              <path
                d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
                stroke="#ffffff"
                stroke-width="2"
              />
            </svg>
          </span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import { ref, watch } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useStore } from "vuex";

const emit = defineEmits(["toggleModal"]);
const auth = useAuth();
const errorMessage = ref(null);
const loading = ref(false);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  clipId: {
    type: Object,
    required: true,
  },
});

const steamAuthCode = ref();

function checkFormat() {
  return steamAuthCode.value?.split("-").length !== 3;
}

function saveSteamAuthCode() {
  loading.value = true;
  axios({
    method: "put",
    url: "/users/update_user",
    data: {
      match_auth_token: steamAuthCode.value,
    },
  })
    .then(() => {
      auth.fetch();
      closeModal();
    })
    .catch((err) => {
      const subjectKey = Object.keys(err.response.data)[0];
      let subject = subjectKey.split("_").join(" ");
      subject = subject.charAt(0).toUpperCase() + subject.slice(1);
      const message = (err.response.data[subjectKey] || [])[0] || "could not be saved";
      errorMessage.value = `${subject} ${message}`;
    })
    .finally(() => {
      loading.value = false;
    });
}

watch(steamAuthCode, () => {
  errorMessage.value = null;
});

function closeModal() {
  emit("close");
}
</script>
