<template>
  <Popover as="div" class="relative inline-block text-left">
    <div>
      <PopoverButton
        class="group w-full rounded-md border border-gray-700 bg-primary-600 duration-200 px-2.5 h-8 text-left text-sm font-medium text-text-primary hover:bg-primary-500 focus:outline-none focus:ring-0"
      >
        <span class="flex w-full items-center justify-between">
          <span class="flex min-w-0 items-center justify-between space-x-3">
            <span class="flex min-w-0 flex-1 flex-col">
              <span class="truncate text-xs font-medium text-text-primary flex gap-x-1 items-center">
                Play Now
                <ChevronDownIcon
                  class="flex-shrink-0 h-3 w-3 text-text-primary group-hover:text-text-primary"
                  aria-hidden="true"
                />
              </span>
            </span>
          </span>
        </span>
      </PopoverButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PopoverPanel
        v-if="state.navigateServers"
        class="absolute w-[240px] mt-2 right-0 origin-top rounded-md border border-gray-700 shadow-lg outline-none bg-gray-950 z-50"
      >
        <div class="flex px-4 pt-4 pb-2 items-center gap-x-2">
          <img src="../assets/icons/leftarrow.svg" @click="state.navigateServers = false" class="cursor-pointer hover:scale-110 duration-300" />
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs whitespace-nowrap">Choose Location</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>
        <div class="w-full flex flex-col pb-2">
          <div v-for="location in serverLocations" class="px-4 flex text-sm items-center py-3 cursor-pointer"
          :class="[location.value === state.activeLocation?.value ? 'text-text-primary bg-gray-800' : ' bg-gray-950 text-text-secondary hover:bg-gray-900']"
          @click="updateServerLocation(location)"
          >
            <flag :rounded="true" :iso="location.flag" class="rounded-full h-4 w-4"/>
            <h2 class="pl-2 flex-1">{{ location.name }}</h2>
            <span v-if="getServerMs(location.value)">{{ getServerMs(location.value) }}ms</span>
          </div>
        </div>

      </PopoverPanel>
      <PopoverPanel
        v-else-if="state.navigateMods"
        class="absolute w-[240px] mt-2 right-0 origin-top rounded-lg border border-gray-700 shadow-lg outline-none bg-gray-950 z-50 overflow-hidden"
      >
        <div class="flex px-4 pt-4 items-center gap-x-2">
          <img src="../assets/icons/leftarrow.svg" @click="state.navigateMods = false" class="cursor-pointer hover:scale-110 duration-300" />
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs whitespace-nowrap">Choose Mode</h2>
          <div class="w-full h-[1px] bg-gray-700" :class="open && 'rotate-180 transform duration-300'" />
        </div>

        <Disclosure v-slot="{ open }" :defaultOpen="true">
          <DisclosureButton class="flex gap-x-2 items-center group cursor-pointer p-4 w-full">
            <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs whitespace-nowrap flex-1 text-left">Featured</h2>
            <ChevronDownIcon class="h-4 w-4 text-text-primary" :class="open && 'rotate-180 transform duration-300'"/>
          </DisclosureButton>
          <DisclosurePanel class="">
            <div v-for="mod in featuredMods" :key="mod.id" v-show="mod.playable && mod.cs2" class="px-4 flex text-sm items-center py-3 cursor-pointer text-text-primary"
            :class="[mod.name === state.activeMod ? 'bg-gray-800' : ' bg-gray-950 hover:bg-gray-900']"
            @click="updateActiveMod(mod)"
            >
              <h2 class="flex-1">{{ mod.friendly_name }}</h2>
            </div>
          </DisclosurePanel>
        </Disclosure>

        <Disclosure v-slot="{ open }" :defaultOpen="true">
          <DisclosureButton class="flex gap-x-2 items-center group cursor-pointer pt-6 pb-4 px-4 w-full">
            <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs whitespace-nowrap flex-1 text-left">All Modes</h2>
            <ChevronDownIcon class="h-4 w-4 text-text-primary" :class="open && 'rotate-180 transform duration-300'" />
          </DisclosureButton>
          <DisclosurePanel class="">
            <div v-for="mod in nonFeaturedMods" :key="mod.id" v-show="mod.playable && mod.cs2" class="px-4 flex text-sm items-center py-3 cursor-pointer text-text-primary"
            :class="[mod.name === state.activeMod ? 'bg-gray-800' : ' bg-gray-950 hover:bg-gray-900']"
            @click="updateActiveMod(mod)"
            >
              <h2 class="flex-1">{{ mod.friendly_name }}</h2>
            </div>
          </DisclosurePanel>
        </Disclosure>

      </PopoverPanel>
      <PopoverPanel
        v-else-if="state.navigateMaps"
        class="absolute w-[240px] mt-2 right-0 origin-top rounded-md border border-gray-700 shadow-lg outline-none bg-gray-950 z-50"
      >
        <div class="flex px-4 pt-4 pb-2 items-center gap-x-2">
          <img src="../assets/icons/leftarrow.svg" @click="state.navigateMaps = false" class="cursor-pointer hover:scale-110 duration-300" />
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs whitespace-nowrap">Choose Map</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>
        <div class="w-full flex flex-col pb-2">
          <div v-for="map in maps" :key="map.id" v-show="map.cs2" class="px-4 flex text-sm items-center py-3 cursor-pointer"
          :class="[map.name === state.activeMap.name ? 'text-text-primary bg-gray-800' : ' bg-gray-950 text-text-secondary hover:bg-gray-900']"
          @click="updateActiveMap(map)"
          >
            <h2 class="flex-1">{{ map.friendly_name }}</h2>
          </div>
        </div>

      </PopoverPanel>
      <PopoverPanel
        v-else
        class="absolute w-[240px] p-4 mt-2 right-0 origin-top rounded-md border border-gray-700 shadow-lg outline-none bg-gray-950 z-50"
      >
        <div class="flex gap-x-2 justify-center items-center pb-2">
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs">Mode</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>

        <div class="flex items-center group cursor-pointer hover:scale-[102%] duration-300" @click="state.navigateMods = true">
          <h2 class="text-sm text-text-primary flex-1">{{ state.activeMod }}</h2>
          <img src="../assets/icons/rightarrow.svg" class="cursor-pointer" />
        </div>
        <div class="flex gap-x-2 justify-center items-center pb-2 pt-4">
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs">Map</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>
        <div class="flex items-center group cursor-pointer hover:scale-[102%] duration-300" @click="state.navigateMaps = true">
          <h2 class="text-sm text-text-primary flex-1">{{ state.activeMap?.friendly_name }}</h2>
          <img src="../assets/icons/rightarrow.svg" class="cursor-pointer hover:scale-110 duration-300" />
        </div>
        <div class="flex gap-x-2 justify-center items-center pb-2 pt-4">
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs">Location</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>
        <div class="flex items-center group cursor-pointer hover:scale-[102%] duration-300" @click="state.navigateServers = true">
          <flag :rounded="true" :iso="state.activeLocation?.flag" class="rounded-full h-4 w-4"/>
          <h2 class="text-sm text-text-primary pl-2">{{ state.activeLocation?.name }}</h2>
          <span class="text-text-disabled px-1">•</span>
          <h2 class="text-sm text-text-primary flex-1">{{ getServerMs(state.activeLocation?.value) }}ms</h2>
          <img src="../assets/icons/rightarrow.svg" class="cursor-pointer hover:scale-110 duration-300" />
        </div>
        <div class="flex gap-x-2 justify-center items-center pb-2 pt-4">
          <h2 class="uppercase text-text-secondary tracking-[1.5px] text-xs">Other</h2>
          <div class="w-full h-[1px] bg-gray-700" />
        </div>
        <div class="flex flex-col">
          <div class="flex py-2">
            <h2 class="text-sm text-text-primary flex-1">Beta Server</h2>
            <Switch
              v-model="state.betaServer"
              :class="state.betaServer ? 'bg-primary-500' : 'bg-gray-600'"
              class="relative inline-flex h-5 w-9 items-center rounded-full"
            >
              <span class="sr-only">Enable notifications</span>
              <span
                :class="state.betaServer ? 'translate-x-4 bg-primary-50' : 'translate-x-[2px] bg-gray-800'"
                class="inline-block h-4 w-4 transform rounded-full transition"
              />
            </Switch>
          </div>
          <button class="bg-primary-600 mt-4 py-2.5 px-3 hover:bg-primary-500 duration-200 cursor-pointer rounded-md flex justify-center text-text-primary text-xs items-center"
            @click="startServer()"
            :disabled="state.serverLoading"
            type="button">
            <img v-if="!state.serverLoading" src="../assets/icons/play.svg" class="w-3 h-3 mr-1 brightness-200">
            <div class="mr-1" v-else>
              <svg class="w-3 h-3 text-white animate-spin" fill="none"
              viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor">
                </path>
              </svg>
            </div>
            Start Server
        </button>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import StartServerModal from "./StartServerModal.vue";
import NotificationsPopup from "./NotificationsPopup.vue";
import { Menu, MenuItem, MenuButton, MenuItems, Switch, Popover, PopoverButton, PopoverPanel, TabGroup, TabList, Tab, TabPanels, TabPanel, Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { reactive } from "vue";
import ServerInfo from "./ServerInfo.vue";
import { mapState, mapMutations } from "vuex";
import { BellIcon, ClockIcon, StarIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/20/solid";
import axios from "axios";
import moment from "moment";
import refragIcon from "../assets/logos/refrag-icon-button.svg";

  export default {
    setup() {
      const state = reactive({
        betaServer: false,
        activeMap: JSON.parse(localStorage.getItem('activeMap')) || {"friendly_name":"Mirage","name":"de_mirage"},
        activeMod: localStorage.getItem('activeMod') || 'Crossfire',
        activeLocation: null,
        serverLoading: false,
        navigateServers: false,
        navigateMods: false,
        navigateMaps: false
      });

      return {
        state,
      };
    },
    data: function () {
      return {
        showNotifications: false,
        globalNotification: false,
        refragIcon: refragIcon,
        websocket: null,
        subscription: null,
        launchSettings: {},
        serverLocations: [],
      };
    },
    props: ["user"],
    components: {
      StartServerModal,
      ServerInfo,
      BellIcon,
      NotificationsPopup,
      StarIcon,
      ClockIcon,
      Menu,
      MenuItem,
      MenuButton,
      MenuItems,
      Switch,
      Popover,
      PopoverButton,
      PopoverPanel,
      TabGroup,
      TabList,
      Tab,
      TabPanels,
      TabPanel,
      ChevronDownIcon,
      ChevronUpIcon,
      Disclosure,
      DisclosureButton,
      DisclosurePanel,
  },
    computed: {
      mods() {
        return this.$store.state.modInfo?.mods || [];
      },
      featuredMods() {
        return this.mods.filter(mod => mod.featured);
      },
      nonFeaturedMods() {
        return this.mods.filter(mod => !mod.featured);
      },
      maps() {
        if(!this.state.activeMod) return [];
        let mod = this.mods.find(mod => mod.name === this.state.activeMod);
        if(!mod) return [];
        return mod.cs_maps.sort((a, b) => a.friendly_name.localeCompare(b.friendly_name));
      },
      ...mapState(["activeTeam"]),
    },
    mounted() {
      this.getServerLocations();

      if(this.state.activeMod === 'Scrim' && this.user.access_level < 2) {
        this.state.activeMod = 'Crossfire';
        localStorage.setItem('activeMod', 'Crossfire');
      }
    },
    unmounted() {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.websocket.disconnect();
        this.subscription = null;
      }
    },
    methods: {
      ...mapMutations(["updateActiveTeamData", "showMessage", "hideMessage", "setActiveServer"]),
      openServerSelector(close) {
        this.state.navigateServers = true;
        close();
      },
      updateActiveMap(map) {
        let mod = this.mods.find(mod => mod.name === this.state.activeMod);
        if(!mod.cs_maps.find(m => m.name === map.name)) {
          this.state.activeMap = mod.cs_maps[0] || '';
        } else {
          this.state.activeMap = map;
        }
        localStorage.setItem('activeMap', JSON.stringify(this.state.activeMap));
        this.state.navigateMaps = false;
      },
      updateActiveMod(mod) {
        this.state.activeMod = mod.name;
        localStorage.setItem('activeMod', mod.name);
        this.updateActiveMap(this.state.activeMap);
        this.state.navigateMods = false;
      },
      updateServerLocation(location){
        this.state.activeLocation = location;
        this.state.navigateServers = false;
        localStorage.setItem('activeLocation', JSON.stringify(location));
      },
      toggleNotifications() {
        this.showNotifications = !this.showNotifications;
      },
      startServer() {
        this.state.serverLoading = true;
        axios({
          url: "/cs_servers/start_new_server",
          method: "POST",
          timeout: 120000,
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
          data: {
            server_location_id: this.state.activeLocation?.id,
            game: 'cs2',
            betaServer: this.state.betaServer,
            secureServer: false,
            is_assessment: false,
            launch_settings: {
              mod: this.state.activeMod.toLowerCase(),
              map: this.state.activeMap.name,
            },
          },
        })
          .then(({ data }) => {
            this.setActiveServer(data);
            setTimeout(() => {
              this.$router.push('/server_manager');
            }, 2000);
          })
          .catch((err) => {
            this.state.serverLoading = false;
            if (err.code == "ECONNABORTED") {
              this.showMessage({
                messageType: "error",
                messageText: `Server launch timed out, your server might still have started. Please reload the page. If no server started, please contact us on Discord.`,
              });
              return;
            }
            this.showMessage({
              messageType: "error",
              messageText: `Failed server launching, do you already have a server running? If not, please contact us on Discord.`,
            });
          });
      },
      getServerLocations() {
        axios({
          method: "get",
          url: "/server_locations/",
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
          .then(({ data }) => {
            this.serverLocations = data;
            this.state.activeLocation = JSON.parse(localStorage.getItem('activeLocation')) || data[0];
          })
      },
      getServerMs(value) {
        if(!this.$store.state.servers) return;

        this.sortServersByMs();
        return this.$store.state.servers.find(server => server.value === value)?.ms;
      },
      sortServersByMs() {
        if(!this.$store.state.servers) return;

        this.serverLocations.sort((a, b) => {
          const aMs = this.$store.state.servers.find(server => server.value === a.value)?.ms
          const bMs = this.$store.state.servers.find(server => server.value === b.value)?.ms

          if (aMs !== -1 && bMs !== -1) {
            return aMs - bMs;
          }
          if (aMs !== -1) {
            return -1;
          }
          if (bMs !== -1) {
            return 1;
          }

          return 0;
        })
      },
    },
  };
</script>
