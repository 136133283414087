<template>
  <div
    v-if="props.match"
    class="flex cursor-pointer"
    @click="router.push(`/matches/${props.match.match_id}`)"
  >
    <div
      class="w-[2px] h-[120px] xl:h-[72px]"
      :class="[props.match.match_result === 1 ? 'bg-success-400' : 'bg-danger-400']"
    />
    <div
      class="flex w-full gap-4"
      :class="[
        props.match.match_result === 1 ? 'bg-success-gradient' : 'bg-danger-gradient',
      ]"
    >
      <div class="hidden xl:flex w-full items-center ml-4 gap-4">
        <div class="flex relative">
          <img
            class="w-12 h-12 rounded-md object-cover opacity-[25%]"
            :src="`/maps/${convertMapName(props.match.map_name)}_cs2.jpg`"
          />
          <img
            class="w-8 h-8 rounded-md object-cover absolute top-0 left-0 translate-x-1/4 translate-y-1/4"
            :src="`/maps/map_icon_${convertMapName(props.match.map_name)}.svg`"
          />
          <div
            v-if="props.match.invalid_match"
            class="absolute right-0 bottom-0 rounded-md bg-warning-900 text-warning-400 px-1 py-1"
            title="Match Invalidated"
          >
            <ExclamationTriangleIcon class="w-3 h-3" />
          </div>
        </div>
        <div class="flex gap-6 flex-1">
          <div class="flex-1 flex flex-col gap-1">
            <div class="text-text-primary font-medium">
              <span :class="[props.match.match_result === 1 ? 'text-success-400' : '']">{{
                getUserTeamScore()
              }}</span>
              -
              <span :class="[props.match.match_result === 0 ? 'text-danger-400' : '']">{{
                getOpponentTeamScore()
              }}</span>
            </div>
            <div class="flex gap-1">
              <img
                class="w-4 h-4 object-cover"
                :src="`/img/icons/${props.match.platform}_icon.png`"
              />
              <h2 class="text-text-secondary text-xs">
                {{ convertMatchTypeName(props.match.match_type) }}
              </h2>
            </div>
          </div>

          <div class="flex-1 flex-col flex gap-1 justify-center">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Rank</h1>
            <h2 class="text-text-primary text-sm flex items-center justify-start">
              <Rank
                :rank="props.match.end_rank"
                :matchType="props.match.match_type"
                :cs_map="props.match.map_name"
              />
              <div v-if="props.match.start_rank">
                <span
                  v-if="props.match.end_rank - props.match.start_rank > 1"
                  class="text-xs pl-1 text-success-400"
                >
                  +{{ props.match.end_rank - props.match.start_rank }}
                </span>
                <span
                  v-else-if="props.match.start_rank - props.match.end_rank > 1"
                  class="text-xs pl-1 text-danger-400"
                >
                  -{{ props.match.start_rank - props.match.end_rank }}
                </span>
                <ArrowUpIcon
                  v-else-if="props.match.end_rank - props.match.start_rank == 1"
                  class="w-4 h-4 text-success-400 ml-1"
                />
                <ArrowDownIcon
                  v-else-if="props.match.start_rank - props.match.end_rank == 1"
                  class="w-4 h-4 text-danger-400 ml-1"
                />
              </div>
            </h2>
          </div>

          <div class="flex-1 flex-col flex gap-1 justify-center">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Date</h1>
            <h2 class="text-text-primary text-sm">
              {{
                props.match.played_on || props.match.date
                  ? moment(props.match.played_on || props.match.date).format(
                      "MMM Do YYYY"
                    )
                  : "-"
              }}
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
              K / D / A
            </h1>
            <h2 class="text-text-primary text-sm">
              {{ props.match.kills == null ? "?" : props.match.kills }} /
              {{ props.match.deaths == null ? "?" : props.match.deaths }} /
              {{ props.match.assists == null ? "?" : props.match.assists }}
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
              HS Kill %
            </h1>
            <h2 class="text-text-primary text-sm">
              {{
                props.match.headshot_kill_percentage == null
                  ? "?"
                  : (props.match.headshot_kill_percentage * 100).toFixed(0)
              }}%
            </h2>
          </div>
          <div class="flex-1 flex-col flex gap-1">
            <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">KPR</h1>
            <h2 class="text-text-primary text-sm">
              {{
                props.match.kills_per_round == null
                  ? "?"
                  : props.match.kills_per_round.toFixed(2)
              }}
            </h2>
          </div>
          <div class="w-24 flex-col items-center justify-center flex gap-1">
            <button
              class="bg-gray-800 rounded-lg py-2 px-2 text-text-primary text-xs hover:bg-gray-700 duration-300"
              @click="router.push(`/matches/${props.match.match_id}`)"
            >
              <img src="../../assets/icons/rightarrow.svg" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex xl:hidden w-full items-center ml-4 gap-4">
        <div class="flex gap-2 py-3 flex-1 flex-col xl:flex-row">
          <div class="flex gap-4">
            <div class="flex relative">
              <img
                class="w-12 h-12 rounded-md object-cover opacity-[25%]"
                :src="`/maps/${convertMapName(props.match.map_name)}_cs2.jpg`"
              />
              <img
                class="w-8 h-8 rounded-md object-cover absolute top-0 left-0 translate-x-1/4 translate-y-1/4"
                :src="`/maps/map_icon_${convertMapName(props.match.map_name)}.svg`"
              />
              <div
                v-if="props.match.invalid_match"
                class="absolute right-0 bottom-0 rounded-md bg-warning-900 text-warning-400 px-1 py-1"
                title="Match Invalidated"
              >
                <ExclamationTriangleIcon class="w-3 h-3" />
              </div>
            </div>
            <div class="flex flex-col gap-1 flex-1">
              <div class="font-medium text-text-primary">
                <span
                  :class="[props.match.match_result === 1 ? 'text-success-400' : '']"
                  >{{ getUserTeamScore() }}</span
                >
                -
                <span
                  :class="[props.match.match_result === 0 ? 'text-danger-400' : '']"
                  >{{ getOpponentTeamScore() }}</span
                >
              </div>
              <div class="flex gap-1">
                <img
                  class="w-4 h-4 object-cover"
                  :src="`/img/icons/${props.match.platform}_icon.png`"
                />
                <h2 class="text-text-secondary text-xs">
                  {{ convertMatchTypeName(props.match.platform) }}
                </h2>
              </div>
            </div>

            <div class="flex-1 flex-col flex gap-1 justify-center">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Rank</h1>
              <h2 class="text-text-primary text-sm flex items-center justify-start">
                <Rank
                  :rank="props.match.end_rank"
                  :matchType="props.match.match_type"
                  :cs_map="props.match.map_name"
                />
                <div v-if="props.match.start_rank">
                  <span
                    v-if="props.match.end_rank - props.match.start_rank > 1"
                    class="text-xs pl-1 text-success-400"
                  >
                    +{{ props.match.end_rank - props.match.start_rank }}
                  </span>
                  <span
                    v-else-if="props.match.start_rank - props.match.end_rank > 1"
                    class="text-xs pl-1 text-danger-400"
                  >
                    -{{ props.match.start_rank - props.match.end_rank }}
                  </span>
                  <ArrowUpIcon
                    v-else-if="props.match.end_rank - props.match.start_rank == 1"
                    class="w-4 h-4 text-success-400 ml-1"
                  />
                  <ArrowDownIcon
                    v-else-if="props.match.start_rank - props.match.end_rank == 1"
                    class="w-4 h-4 text-danger-400 ml-1"
                  />
                </div>
              </h2>
            </div>

            <div class="flex-col flex gap-1 justify-center flex-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">Date</h1>
              <h2 class="text-text-primary text-sm">
                {{
                  props.match.played_on || props.match.date
                    ? moment(props.match.played_on || props.match.date).format(
                        "MMM Do YYYY"
                      )
                    : "-"
                }}
              </h2>
            </div>

            <div class="w-24 flex-col items-center justify-center flex gap-1">
              <button
                class="bg-gray-800 rounded-lg py-2 px-2 text-text-primary text-xs hover:bg-gray-700 duration-300"
                @click="router.push(`/matches/${props.match.match_id}`)"
              >
                <img src="../../assets/icons/rightarrow.svg" class="w-4 h-4" />
              </button>
            </div>
          </div>

          <div class="flex gap-2">
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
                K / D / A
              </h1>
              <h2 class="text-text-primary text-sm">
                {{ props.match.kills }} / {{ props.match.deaths }} /
                {{ props.match.assists }}
              </h2>
            </div>
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">
                HS Kill %
              </h1>
              <h2 class="text-text-primary text-sm">
                {{
                  props.match.headshot_kill_percentage
                    ? (props.match.headshot_kill_percentage * 100).toFixed(0)
                    : "?"
                }}%
              </h2>
            </div>
            <div class="flex-1 flex-col flex gap-1">
              <h1 class="text-text-secondary text-xs uppercase tracking-[1.5px]">KPR</h1>
              <h2 class="text-text-primary text-sm">
                {{ props.match.kills_per_round.toFixed(2) }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script setup>
import moment from "moment";
import { useRouter } from "vue-router";
import { computed } from "vue";
import Rank from "../Matches/Rank.vue";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/vue/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

const router = useRouter();
const emit = defineEmits(["toggleAllstar"]);

const props = defineProps({
  match: {
    type: Object,
    required: true,
  },
});

const matchScore = computed(() => {
  if (!props.match?.match_score) return [0, 0];

  const scores = props.match.match_score.split("-").map(Number);
  return scores.sort((a, b) => b - a); // Sort in descending order
});

function convertMapName(name) {
  return name?.includes("wm_") ? name.replace("wm_", "de_") : name;
}

function convertMatchTypeName(name) {
  // Capitalize first letter
  return name.charAt(0).toUpperCase() + name.slice(1);
}

function getUserTeamScore() {
  if (!matchScore.value) return 0;

  if (props.match.match_result === 1) {
    return Math.max(matchScore.value[0], matchScore.value[1]);
  } else {
    return Math.min(matchScore.value[0], matchScore.value[1]);
  }
}

function getOpponentTeamScore() {
  if (!matchScore.value) return 0;

  if (props.match.match_result === 1) {
    return Math.min(matchScore.value[0], matchScore.value[1]);
  } else {
    return Math.max(matchScore.value[0], matchScore.value[1]);
  }
}
</script>

<style scoped>
.bg-success-gradient {
  background: linear-gradient(90deg, rgba(10, 72, 64, 0.2) 0%, rgba(10, 72, 64, 0) 100%);
}
.bg-danger-gradient {
  background: linear-gradient(
    90deg,
    rgba(103, 19, 19, 0.2) 0%,
    rgba(103, 19, 19, 0) 100%
  );
}
</style>
