<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import NoRoundInfo from "../../assets/no-round-info.png";
</script>

<template>
  <Dashboard
    :title="
      demo.team_names
        ? `${demo.team_names[0]} vs ${demo.team_names[1]} on ${map}`
        : 'Your Demo'
    "
  >
    <div v-if="demo.status == 'ready'">
      <div class="tile is-ancestor">
        <dl
          class="grid grid-cols-1"
          :class="$auth.user().pro_access ? 'sm:grid-cols-6' : 'sm:grid-cols-5'"
        >
          <StatBlock :title="score" subtitle="score" v-if="score" />
          <StatBlock :title="demo.name" subtitle="name" v-if="demo.name" />
          <StatBlock :title="map" subtitle="map" v-if="map" />
          <StatBlock
            :title="moment(demo.date).format('ll')"
            subtitle="date"
            v-if="demo.date"
          />
          <StatBlock
            :title="demo.round_info.m_tickrate"
            subtitle="tickrate"
            v-if="demo.round_info"
          />
          <div
            class="px-4 py-5 bg-gray-950 border-y border-r border-gray-700 overflow-hidden sm:p-6 items-center justify-center flex flex-col"
            v-if="$auth.user().pro_access"
          >
            <dt
              class="text-sm font-medium text-gray-300 truncate items-center"
            >
              COMMS FILE
              <CheckCircleIcon
                class="w-4 ml-2 text-green-500"
                v-if="demo.signed_audio_url"
              />
            </dt>
            <dd
              class="mt-1 text-3xl font-semibold text-gray-900 font-header"
            >
              <a
                :href="demo.signed_audio_url"
                target="_blank"
                v-if="demo.signed_audio_url"
                class="text-blue-500"
              >
                Download
              </a>
              <div class="w-full" v-else>
                <input
                  id="file-upload"
                  class="sr-only"
                  type="file"
                  name="audioFile"
                  accept=".opus"
                  @change="processAudioFile($event)"
                  ref="file"
                />
                <button
                  class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                  @click="$refs.file.click()"
                  v-if="!uploadPercentage"
                >
                  Upload Comms Audio File
                </button>
                <div
                  class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700"
                  v-if="uploadPercentage && uploadPercentage < 100"
                >
                  <div
                    class="bg-blue-600 h-2.5 rounded-full"
                    :style="{ width: `${uploadPercentage}%` }"
                  ></div>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div v-if="!demo.pov_locked">
        <div class="rounded bg-gray-950" v-if="teams">
          <TeamRow
            :team="team"
            :rounds="rounds"
            v-for="team in teams"
            v-bind:key="team.m_szClanTeamname"
            :selectRound="selectRound"
          />
          <div class="flex flex-row mt-2">
            <div class="teamImage"></div>
            <div
              class="text-center"
              v-for="(round, index) in rounds"
              :key="index"
              :style="{ width: `${(1 / rounds.length) * 100}%` }"
            >
              <span class="text-white">{{ index + 1 }}</span>
            </div>
          </div>
        </div>

        <div class="rounded bg-gray-950 pt-5 text-white" v-if="teams">
          <div class="roundRow">
            <div class="flex-row flex justify-end p-4">
              <span class="border px-2 mr-2 rounded items-center inline-flex">{{
                rounds[selectedRound - 1].winner_team
              }}</span>
              <span
                class="border px-2 mr-2 rounded items-center inline-flex"
                v-if="rounds[selectedRound - 1].bomb_planted !== 'NULL'"
                ><i class="fas fa-bomb mr-1"></i>
                {{ rounds[selectedRound - 1].bomb_planted }}</span
              >
              <span class="border px-2 mr-2 rounded items-center inline-flex">{{
                formatRoundReason(rounds[selectedRound - 1].message)
              }}</span>
              <div class="select rounded mr-2">
                <select v-model="selectedRound" class="boxedSelect rounded text-gray-700">
                  <option v-for="(_, index) in rounds" :key="index">
                    {{ index + 1 }}
                  </option>
                </select>
              </div>
              <button
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                v-on:click="startDemo('demos')"
              >
                <PlayIcon class="mr-2 w-6 h-6" />
                <span id="startDemoFromRound">Start</span>
              </button>
            </div>
            <div class="flex" v-if="rounds[selectedRound - 1].teamdata">
              <div class="w-full">
                <RoundTable
                  team="T"
                  :winner="rounds[selectedRound - 1].winner_team"
                  :teamName="rounds[selectedRound - 1].teamdata[0].m_szClanTeamname"
                  :players="sortPlayers(rounds[selectedRound - 1].teamdata[0].playerdata)"
                />
              </div>
              <div class="w-full">
                <RoundTable
                  team="CT"
                  :winner="rounds[selectedRound - 1].winner_team"
                  :teamName="rounds[selectedRound - 1].teamdata[1].m_szClanTeamname"
                  :players="sortPlayers(rounds[selectedRound - 1].teamdata[1].playerdata)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="rounded bg-gray-950" v-if="notes">
          <div
            class="flex mb-4 flex-col bg-gray-900 p-6"
          >
            <h3 class="text-lg leading-6 font-medium text-white">Notes</h3>
            <p class="mt-1 text-sm text-gray-300">
              (Add a note in game by typing !note "text goes here")
            </p>
          </div>
          <table class="min-w-full divide-y divide-gray-600">
            <thead>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
              >
                Round
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
              >
                Time Remaining
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
              >
                Content
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
              ></th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
              ></th>
            </thead>
            <tbody class="divide-y divide-gray-600">
              <tr v-for="note in notes.sort((a, b) => a.round > b.round)" :key="note.id">
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {{ note.round }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {{ formatTime(note.time_remaining) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-white">
                  {{ note.content }}
                </td>
                <td>
                  <button
                    class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                    v-on:click="startDemo('notes', note.id)"
                    id="StartDemoFromNote"
                  >
                    Start demo from note
                  </button>
                </td>
                <td>
                  <button
                    class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                    v-on:click="deleteNote(note.id)"
                  >
                    Delete Note
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="columns-3 gap-0 pt-10">
          <div class="w-full" v-if="demo.status == 'broken'">
            <article class="tile is-child box buttonBox">
              <button class="button is-primary" v-on:click="analyzeDemo">
                Analyze Demo
              </button>
            </article>
          </div>
          <div
            class="bg-gray-900 px-4 py-5 sm:px-6 border-r border-t border-gray-700"
            v-if="demo.status == 'ready'"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
            >
              <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-white">Choose a round</h3>
                <p class="mt-1 text-sm text-gray-300">And start it on your server</p>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="control is-expanded mr-2">
                <select v-model="selectedRound">
                  <option v-for="(round, index) in rounds" :key="index">
                    {{ index + 1 }}
                  </option>
                </select>
              </div>
              <div class="control">
                <button
                  class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
                  v-on:click="startDemo('demos')"
                  id="startDemoBottom"
                >
                  Start Demo On Server
                </button>
              </div>
            </div>
          </div>
          <div
            class="bg-gray-900 px-4 py-5 sm:px-6 border-r border-t border-gray-700"
            v-if="demo.status == 'ready' && demo.demo_url"
          >
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
            >
              <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-white">Download Demo</h3>
                <p class="mt-1 text-sm text-gray-300">
                  Download this demo to your computer
                </p>
              </div>
            </div>

            <div class="control">
              <a
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
                :href="demo.demo_url"
                target="_blank"
              >
                <ArrowDownTrayIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Download Demo
              </a>
            </div>
          </div>
          <div class="rounded bg-gray-900 px-4 py-5 sm:px-6" v-if="requireConfirmation">
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
            >
              <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-white">
                  Are you sure you want to delete this demo?
                </h3>
                <p class="mt-1 text-sm text-gray-300">You can't go back after this!</p>
              </div>
            </div>
            <div>
              <button
                class="mr-2 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-red-500"
                v-on:click="cancelDeletion"
              >
                No!
              </button>
              <button
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-blue-500"
                v-on:click="deleteDemo"
              >
                Yes!
              </button>
            </div>
          </div>
          <div class="bg-gray-900 px-4 py-5 sm:px-6 border-r border-t border-gray-700" v-if="!requireConfirmation">
            <div
              class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
            >
              <div class="ml-4 mt-4">
                <h3 class="text-lg leading-6 font-medium text-white">Delete this demo</h3>
                <p class="mt-1 text-sm text-gray-300">Want to get rid of this demo?</p>
              </div>
            </div>
            <button
              class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-red-500"
              v-on:click="askForDeleteConfirmation"
            >
              Delete Demo
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="NoRoundInfo" class="w-full mt-5" />
      </div>
    </div>
    <div class="rounded bg-white px-4 py-5 sm:px-6" v-if="demo.status == 'created'">
      <div
        class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
      >
        <div class="ml-4 mt-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            We're analyzing your demo
          </h3>
          <p class="mt-1 text-sm text-gray-300">
            This won't take long and we'll refresh this page when it's done!
          </p>
        </div>
      </div>
    </div>
    <div
      class="rounded bg-white px-4 py-5 sm:px-6"
      v-if="demo.status == 'broken' || demo.status == 'analysis_failed'"
    >
      <div
        class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap mb-4"
      >
        <div class="ml-4 mt-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Demo Corrupted</h3>
          <p class="mt-1 text-sm text-gray-300">
            This demo is corrupted. Please contact an admin.
          </p>
        </div>
      </div>
    </div>
  </Dashboard>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

import TeamRow from "/src/components/Restrat/Demo/TeamRow.vue";
import RoundTable from "/src/components/Restrat/Demo/RoundTable.vue";
import StatBlock from "/src/components/Restrat/Demo/StatBlock.vue";
import { PlayIcon, CheckCircleIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/outline";

import { objSort } from "/src/utils/array_utils";

export default {
  components: {
    TeamRow,
    RoundTable,
    StatBlock,
    PlayIcon,
    CheckCircleIcon,
    ArrowDownTrayIcon,
  },
  data: function data() {
    return {
      demo: {},
      teams: null,
      rounds: null,
      notes: null,
      score: "",
      map: null,
      requireConfirmation: null,
      selectedRound: 1,
      moment,
      audioFile: null,
      uploadPercentage: 0,
    };
  },
  head: {
    title: () => this.demo?.team_names
      ? `Refrag | ${this.demo.team_names[0]} vs ${this.demo.team_names[1]} on ${this.map}`
      : 'Refrag | Demo Viewer',
  },
  mounted: function mounted() {
    this.$cable.useGlobalConnection().subscriptions.create(
      { channel: "DemoChannel", id: this.$route.params.id },
      {
        received: () => {
          this.getDemo();
        },
      }
    );
    window.refs = this.$refs;
    this.getDemo();
  },
  computed: {
    ...mapState(["activeTeam"]),
    user() {
      return this.$auth.user() || {};
    },
    team() {
      //Checks to see which team to display based on activeTeamId
      for (var i = 0; i < this.user.teams.length; i++) {
        if (this.user.teams[i].id === Number(localStorage.activeTeamId)) {
          return this.user.teams[i];
        }
      }
      return this.user.teams[0] || {};
    },
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    getDemo() {
      const { id } = this.$route.params;
      axios({
        url: `demos/${id}.json`,
        headers: {
          "X-TEAM-ID": this.activeTeam.id,
        },
      }).then((res) => {
        this.saveDemo(res.data);
      });
    },
    processAudioFile(event) {
      [this.audioFile] = event.target.files;
      axios
        .put(this.demo.audio_upload_url, this.audioFile, {
          onUploadProgress: function onUploadProgress(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total),
              10
            );
          }.bind(this),
        })
        .then(() => {
          this.getDemo();
        });
    },
    startDemo: async function startDemo(type = "demos", id = this.$route.params.id) {
      if (!this.team.cs_servers.length) {
        this.showMessagePopup(
          "error",
          "Please start a server before you load a demo onto it",
          false
        );
        return;
      }
      axios
        .get(`demos/${this.$route.params.id}/check_if_demo_ready.json`, {
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
        .then(() => {
          axios
            .post(
              `${type}/${id}/start_demo.json`,
              {
                round: this.selectedRound,
              },
              {
                headers: {
                  "X-TEAM-ID": this.activeTeam.id,
                },
              }
            )
            .then(() => {
              this.showMessagePopup(
                "success",
                "Demo successfully loaded on the server!",
                true
              );
            })
            .catch((err) => {
              this.showMessagePopup("error", err.response.data.errors, false);
            });
        })
        .catch((err) => {
          this.showMessagePopup("error", err.response.data.errors[0], false);
        });
    },
    saveDemo(demo) {
      this.demo = demo;
      if (demo.round_info) {
        this.rounds = demo.round_info.rounddata;
        this.teams = demo.round_info.teams.map((team) => {
          const sortedTeam = team;
          sortedTeam.playerdata = this.sortedPlayers(team.playerdata);
          return team;
        });
        this.score = demo.round_info.teams.map((team) => team.m_scoreTotal).join(":");
        this.map = demo.round_info.demoheader.mapname;
        this.notes = demo.notes;
      }
    },
    showMessagePopup(messageType, messageText, hideAutomatically = false) {
      this.showMessage({ messageType, messageText });
      if (hideAutomatically) {
        setTimeout(this.hideMessage, 3000);
      }
    },
    analyzeDemo() {
      const { id } = this.$route.params;
      axios
        .post(
          `demos/${id}/analyze.json`,
          {},
          {
            headers: {
              "X-TEAM-ID": this.activeTeam.id,
            },
          }
        )
        .then(() => {
          this.$router.go();
        });
    },
    askForDeleteConfirmation() {
      this.requireConfirmation = true;
    },
    cancelDeletion() {
      this.requireConfirmation = false;
    },
    deleteDemo: function deleteDemo() {
      const { id } = this.$route.params;
      axios
        .delete(`demos/${id}.json`, {
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
        .then(() => {
          this.$router.push({
            name: "Restrat",
          });
        });
    },
    deleteNote: function deleteNote(id) {
      axios
        .delete(`notes/${id}.json`, {
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
        .then(() => {
          this.$router.go();
        });
    },
    sortedPlayers: function sortedPlayers(players) {
      return players.sort((player1, player2) => player2.kills - player1.kills);
    },
    formatTime: function formatTime(timeRemaining) {
      const minutes = Math.floor(timeRemaining / 60);
      let seconds = timeRemaining - minutes * 60;
      seconds = seconds > 9 ? seconds : `0${seconds}`;
      return `${minutes}:${seconds}`;
    },
    selectRound: function selectRound(round) {
      this.selectedRound = this.rounds.indexOf(round) + 1;
    },
    formatRoundReason: function formatRoundReason(reason) {
      switch (reason) {
        case "#SFUI_Notice_Target_Bombed":
          return "Bomb Detonated";
        case "#SFUI_Notice_Terrorists_Win":
          return "CTs Eliminated";
        case "#SFUI_Notice_CTs_Win":
          return "Ts Eliminated";
        case "#SFUI_Notice_Bomb_Defused":
          return "Bomb Defused";
        case "#SFUI_Notice_Target_Saved":
          return "Target Saved";
        default:
          return "Target Saved";
      }
    },
    sortPlayers: function sortPlayers(players) {
      const sortablePlayers = players.slice();
      return sortablePlayers.sort(objSort("-kills"));
    },
  },
};
</script>

<style lang="scss">
.teamImage {
  float: left;
  width: 150px;
  min-width: 150px;
}
.roundOverview {
  .teamRow:first-of-type {
    .roundHistory {
      .column {
        &:nth-of-type(2n) {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  .teamRow:nth-of-type(2) {
    .roundHistory {
      .column {
        &:nth-of-type(2n + 1) {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
.t_win {
  color: #efb41d;
}

.ct_win {
  color: #338fce;
}
</style>
