<template>
  <div class="bg-gray-950 z-10 border-b border-gray-700">
    <div class="hidden md:flex md:items-center h-16">
      <div class="flex ml-12 w-full" v-if="canStartServer()">
        <div class="flex w-full justify-end" v-if="!activeServer?.id">
          <PlayNowDropdown v-bind:user="user"></PlayNowDropdown>
          <div class="w-[1px] h-full bg-gray-700 h-3/5 ml-4 my-auto"></div>
        </div>
        <div
          v-else-if="activeServer.status && activeServer?.status !== 'online'"
          class="w-full flex justify-end gap-2"
        >
          <div
            class="inline-flex h-9 rounded-md justify-end items-center px-3 h-8 border select-none border-transparent text-sm font-medium text-white bg-primary-600 hover:bg-primary-500"
          >
            <svg class="w-6 h-6 text-white animate-spin" fill="none" viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"
              ></path>
            </svg>
          </div>

          <div
            @click="toggleTerminate()"
            class="flex items-center justify-center bg-gray-800 cursor-pointer rounded-lg h-9 w-9 hover:bg-gray-700 duration-300"
          >
            <XMarkIcon class="w-5 h-5 text-text-secondary" @click="openServerSelector" />
          </div>
        </div>
        <div class="flex" v-else>
          <ServerInfo
            :ip="serverIP"
            :port="serverPort"
            :password="serverPassword"
            :id="serverID"
            :isAssessment="isAssessment"
            :raw_ip="serverRawIP"
          />
        </div>
      </div>

      <div class="mt-4 flex flex-1 justify-end md:mt-0 md:ml-4" v-else>
        <div v-if="serverLauncherDisabled" class="has-tooltip flex flex-col">
          <button
            type="button"
            class="text-white bg-gray-300 font-medium text-sm px-5 h-8 text-center"
          >
            Under Maintenance
          </button>
          <span
            href="https://discord.gg/Refrag"
            class="tooltip rounded shadow-md p-1 bg-gray-900 text-white mr-2 mt-16"
            >CS:GO and CS2 servers are both under maintenance.</span
          >
        </div>
        <div v-else class="flex flex-col">
          <button
            @click="$emit('openUpgradeModal')"
            type="button"
            class="text-white bg-primary-600 hover:bg-primary-500 duration-300 font-medium text-sm px-5 h-8 text-center rounded-md"
          >
            Play Now
          </button>
        </div>
      </div>
      <Streaks :user="user" />
      <div class="mt-4 flex justify-end md:mt-0 md:ml-4 h-5">
        <img
          src="../../assets/icons/bell.svg"
          class="inline-flex items-center text-white float-right cursor-pointer hover:scale-[1.1] duration-300"
          @click="toggleNotifications"
        />
        <div
          v-if="state.notifications.length > 0"
          class="bg-red-500 h-fit px-[4px] rounded-full text-white text-xs select-none relative w-auto right-[10px] -top-1"
        >
          {{ state.notifications.length }}
        </div>

        <div class="pr-4 flex justify-center items-center">
          <Menu as="div" class="relative inline-block text-left pl-4">
            <div>
              <MenuButton
                class="flex group w-full rounded-full text-left text-sm font-medium text-gray-700"
              >
                <span
                  class="flex w-full items-center justify-center hover:scale-105 duration-300"
                >
                  <img class="w-8 min-w-8 h-8 bg-red-500 rounded-full" :src="imageURL" />
                  <img class="pl-2" src="../../assets/icons/dropdown.svg" />
                </span>
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-8 w-36 origin-top-right border border-gray-700 shadow-lg divide-y divide-gray-700 rounded-md bg-gray-900 shadow-lg"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <div class="py-1">
                  <div class="px-4 py-3" role="none">
                    <p class="text-sm text-text-secondary" role="none">Signed in as</p>
                    <p class="truncate text-sm font-medium text-text-primary" role="none">
                      {{ username }}
                    </p>
                  </div>
                  <div class="py-1" role="none">
                    <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                    <router-link
                      :to="'/user/' + getUserLink"
                      class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      >My Profile</router-link
                    >
                    <router-link
                      v-if="user.access_level > 2"
                      to="/team"
                      class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-1"
                      >My Team</router-link
                    >
                    <router-link
                      to="/settings"
                      class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-2"
                      >Settings</router-link
                    >
                  </div>
                  <div class="py-1" role="none">
                    <button
                      @click="logout"
                      class="text-danger-500 block w-full px-4 py-2 text-left text-sm hover:bg-gray-700"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-3"
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>

    <div class="flex md:hidden bg-gray-900 h-[52px]">
      <div class="flex p-4 w-full items-center justify-center">
        <img
          @click="toggleMobileMenu"
          src="../../assets/icons/hamburger.svg"
          class="w-5 h-5 m-auto cursor-pointer"
        />
        <img src="../../assets/logos/refrag-horz-fill.svg" class="ml-3 h-5" />
        <div class="flex-1" />

        <div class="flex pr-3" v-if="canStartServer()">
          <div
            class="flex w-full justify-end"
            v-if="!activeServer?.id || activeServer.status !== 'online'"
          >
            <PlayNowDropdown v-bind:user="user"></PlayNowDropdown>
          </div>
          <div
            class="inline-flex h-9 rounded-md items-center px-3 h-8 border select-none border-transparent text-sm font-medium text-white bg-primary-600 hover:bg-primary-500"
            v-else-if="activeServer.status && activeServer?.status !== 'online'"
          >
            <svg class="w-6 h-6 text-white animate-spin" fill="none" viewBox="0 0 24 24">
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div v-else>
            <router-link
              to="/server_manager"
              type="button"
              class="inline-flex rounded-md items-center px-3 h-8 border select-none border-transparent text-sm text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
            >
              Manage
            </router-link>
          </div>
        </div>

        <div class="flex flex-1 justify-end pr-3" v-else>
          <div v-if="serverLauncherDisabled" class="has-tooltip flex flex-col">
            <button
              type="button"
              class="text-white bg-gray-300 font-medium text-sm px-5 h-8 text-center"
            >
              Under Maintenance
            </button>
            <span
              href="https://discord.gg/Refrag"
              class="tooltip rounded shadow-md p-1 bg-gray-900 text-white mr-2 mt-16"
              >CS:GO and CS2 servers are both under maintenance.</span
            >
          </div>
          <div v-else class="has-tooltip flex flex-col">
            <button
              @click="$emit('openUpgradeModal')"
              type="button"
              class="text-white bg-primary-600 hover:bg-primary-500 duration-300 font-medium text-sm px-5 h-8 text-center rounded-md whitespace-nowrap"
            >
              Play Now
            </button>
          </div>
        </div>

        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="flex group w-full rounded-full text-left text-sm font-medium text-gray-700"
            >
              <span
                class="flex w-full items-center justify-center hover:scale-105 duration-300"
              >
                <img class="w-8 h-8 bg-red-500 rounded-full" :src="imageURL" />
              </span>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-10 mt-2 w-36 origin-top-right border border-gray-700 shadow-lg divide-y divide-gray-700 rounded-md bg-gray-900 shadow-lg"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="menu-button"
            >
              <div class="py-1">
                <div class="px-4 py-3" role="none">
                  <p class="text-sm text-text-secondary" role="none">Signed in as</p>
                  <p class="truncate text-sm font-medium text-text-primary" role="none">
                    {{ username }}
                  </p>
                </div>
                <div class="py-1" role="none">
                  <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                  <router-link
                    :to="'/user/' + getUserLink"
                    class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-0"
                    >My Profile</router-link
                  >
                  <router-link
                    v-if="user.access_level > 2"
                    to="/team"
                    class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-1"
                    >My Team</router-link
                  >
                  <router-link
                    to="/settings"
                    class="text-text-secondary block px-4 py-2 text-sm hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-2"
                    >Settings</router-link
                  >
                </div>
                <div class="py-1" role="none">
                  <button
                    @click="logout"
                    class="text-danger-500 block w-full px-4 py-2 text-left text-sm hover:bg-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-3"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </div>

    <StartServerModal
      @closeModal="closeModal"
      :open="state.modalOpen"
      :canStartCs2Server="launchCs2Server"
      :canStartCsgoServer="launchCsgoServer"
      :redirect="true"
      v-if="state.modalOpen"
    />
  </div>
  <NotificationsPopup
    @toggleNotifications="toggleNotifications"
    v-bind:showNotifications="showNotifications"
    :notifications="state.notifications"
    @see-notification="seeNotification"
    @clear-all-notifications="clearAllNotifications"
  />
  <div
    class="mt-4 -mb-4 mx-8 text-white p-1 border-2 border-red-500"
    v-if="globalNotification"
  >
    <p class="m-auto text-center text-sm font-md">
      Hello, this is a global notification. What are we notifying you of? I'm not sure,
      but I'm here, notifying you! Do you feel notified yet?
    </p>
  </div>
</template>

<script>
import StartServerModal from "../StartServerModal.vue";
import NotificationsPopup from "../NotificationsPopup.vue";
import {
  Menu,
  MenuItem,
  MenuButton,
  MenuItems,
  Switch,
  Popover,
  PopoverButton,
  PopoverPanel,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@headlessui/vue";
import { reactive } from "vue";
import ServerInfo from "../ServerInfo.vue";
import { mapState, mapMutations } from "vuex";
import { BellIcon, ClockIcon, StarIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon, ChevronUpIcon, XMarkIcon } from "@heroicons/vue/20/solid";
import axios from "axios";
import moment from "moment";
import refragIcon from "../../assets/logos/refrag-icon-button.svg";
import PlayNowDropdown from "../PlayNowDropdown.vue";
import Streaks from "./Streaks/Streaks.vue";

export default {
  setup() {
    const state = reactive({
      modalOpen: false,
      notifications: [],
      siteSettings: [],
      betaServer: false,
      scrimServer: false,
      activeMap: JSON.parse(localStorage.getItem("activeMap")) || {
        friendly_name: "Mirage",
        name: "de_mirage",
      },
      activeMod: localStorage.getItem("activeMod") || "Crossfire",
      activeLocation: null,
      serverLoading: false,
      navigateServers: false,
      navigateMods: false,
      navigateMaps: false,
    });

    function toggleModal() {
      state.modalOpen = !state.modalOpen;
    }

    function closeModal() {
      state.modalOpen = false;
    }

    return {
      toggleModal,
      state,
      closeModal,
    };
  },
  data: function () {
    return {
      showNotifications: false,
      globalNotification: false,
      refragIcon: refragIcon,
      websocket: null,
      subscription: null,
      launchSettings: {},
      serverLocations: [],
      state: {
        copySucceeded: false,
        terminateSucceeded: false,
        isTerminating: false,
      },
    };
  },
  props: ["user", "toggleMobileMenu"],
  emits: ["openUpgradeModal"],
  components: {
    StartServerModal,
    ServerInfo,
    BellIcon,
    NotificationsPopup,
    StarIcon,
    ClockIcon,
    Menu,
    MenuItem,
    MenuButton,
    MenuItems,
    Switch,
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    ChevronDownIcon,
    ChevronUpIcon,
    PlayNowDropdown,
    Streaks,
    XMarkIcon
  },
  computed: {
    activeServer() {
      return this.$store.state.activeServer;
    },
    getUserLink() {
      if (this.user.username) {
        return this.user.username;
      }
      return this.user.uuid;
    },
    username() {
      if (this.user.username) {
        return this.user.username;
      } else if (this.user.steam_profile) {
        return this.user.steam_profile.personaname;
      }
      return this.user.email;
    },
    imageURL() {
      if (this.user.steam_profile) {
        return this.user.steam_profile.avatarfull;
      }
      return this.refragIcon;
    },
    mods() {
      return this.$store.state.modInfo.mods;
    },
    launchCsgoServer() {
      let start_csgo_server = this.state.siteSettings.find(
        (setting) => setting.name === "start_csgo_server"
      )?.enabled;
      if (typeof start_csgo_server !== "undefined") return start_csgo_server;
      return true;
    },
    launchCs2Server() {
      let start_cs2_server = this.state.siteSettings.find(
        (setting) => setting.name === "start_cs2_server"
      )?.enabled;
      if (typeof start_cs2_server !== "undefined") return start_cs2_server;
      return true;
    },
    serverIP() {
      if (!this.activeServer) return null;
      return this.activeServer.ip;
    },
    serverRawIP() {
      if (!this.activeServer) return null;
      return this.activeServer.raw_ip;
    },
    serverPort() {
      if (!this.activeServer) return null;
      return this.activeServer.port;
    },
    serverPassword() {
      if (!this.activeServer) return null;
      return this.activeServer.password;
    },
    serverID() {
      if (!this.activeServer) return null;
      return this.activeServer.id;
    },
    isAssessment() {
      if (!this.activeServer) return null;
      return this.activeServer.is_assessment;
    },
    activeTeamServer() {
      if (!this.activeServer) return null;
      return this.activeServer;
    },
    serverLauncherDisabled() {
      if (!this.launchCs2Server && !this.launchCsgoServer) {
        return true;
      }
    },
    ...mapState(["activeTeam"]),
  },
  mounted() {
    this.getCsServer();
    this.getNotifications();
    this.loadSiteSettings();
  },
  unmounted() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.websocket.disconnect();
      this.subscription = null;
    }
  },
  methods: {
    ...mapMutations(["updateActiveTeamData", "showMessage", "hideMessage"]),
    subscribeToServer() {
      if (this.subscription) return;
      if (!this.activeServer) return;

      this.websocket = this.$cable.connect();
      this.subscription = this.websocket.subscriptions.create(
        { channel: "CsServerChannel", id: this.serverID },
        {
          received: (data) => {
            this.$store.state.activeServer = data;
          },
        }
      );
    },
    canStartServer() {
      if (!this.user.teams) return false;
      if (this.serverLauncherDisabled) return false;
      let activeTeam = this.user.teams.find(
        (team) => team.id === this.$store.state.activeTeam.id
      );
      return this.user.access_level > 0 && activeTeam && activeTeam.can_start_server;
    },
    finishTerminate() {
      this.state.terminateSucceeded = true;
      this.state.isTerminating = false;
      setTimeout(() => {
        this.updateActiveTeamData(this.$auth);
        this.setActiveServer({});
      }, 2000);
    },
    toggleTerminate() {
      if (this.isTerminating || this.state.terminateSucceeded) return;
      if (confirm("Are you sure you want to terminate the server?")) {
        this.state.isTerminating = true;
        axios({
          url: `/cs_servers/${this.serverID}`,
          method: "DELETE",
          headers: {
            "X-TEAM-ID": this.activeTeam.id,
          },
        })
          .then(() => {
            this.finishTerminate();
          })
          .catch(() => {
            this.showMessage({
              messageType: "error",
              messageText:
                "Failed to terminate server, please wait 10 minutes or contact support.",
            });
          });
      }
    },
    openServerSelector(close) {
      this.state.navigateServers = true;
      close();
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    loadSiteSettings() {
      axios.get(`settings`).then(({ data }) => {
        this.state.siteSettings = data.settings;
        this.$store.state.siteSettings = data.settings;
      });
    },
    getCsServer() {
      axios({
        url: "/cs_servers/running",
        method: "GET",
        headers: {
          "X-TEAM-ID": this.activeTeam.id,
        },
      }).then((response) => {
        if (!response.data) {
          this.$store.state.activeServer = null;
          return;
        }
        this.$store.state.activeServer = response.data;
        this.activeServer = response.data;
        this.subscribeToServer();
      });
    },
    getNotifications() {
      axios.get("/notifications?level=0").then((response) => {
        this.state.notifications = response.data;
      });
    },
    seeNotification(notification) {
      axios.post("/notifications/" + notification.id + "/see").then((response) => {
        this.state.notifications = response.data;
      });
    },
    clearAllNotifications() {
      axios.post("/notifications/see_all").then((response) => {
        this.state.notifications = response.data;
        this.toggleNotifications();
      });
    },
    getServerLocations() {
      axios({
        method: "get",
        url: "/server_locations",
        headers: {
          "X-TEAM-ID": this.activeTeam.id,
        },
      }).then(({ data }) => {
        this.serverLocations = data;
        this.state.activeLocation =
          JSON.parse(localStorage.getItem("activeLocation")) || data[0];
      });
    },
    getServerMs(value) {
      if (!this.$store.state.servers) return;

      this.sortServersByMs();
      return this.$store.state.servers.find((server) => server.value === value)?.ms;
    },
    sortServersByMs() {
      if (!this.$store.state.servers) return;
      if (!this.serverLocations) return;

      this.serverLocations.sort((a, b) => {
        const aMs = this.$store.state.servers.find((server) => server.value === a.value)
          ?.ms;
        const bMs = this.$store.state.servers.find((server) => server.value === b.value)
          ?.ms;

        if (aMs !== -1 && bMs !== -1) {
          return aMs - bMs;
        }
        if (aMs !== -1) {
          return -1;
        }
        if (bMs !== -1) {
          return 1;
        }

        return 0;
      });
    },
    logout() {
      this.$auth.logout({
        makeRequest: true,
        redirect: { name: "Login" },
      });
    },
  },
  watch: {
    activeServer: {
      handler: function (val) {
        if (val) {
          this.subscribeToServer();
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.boxshadow-b {
  box-shadow: 0px 1px 5px 0px #0b0e13;
}
</style>
