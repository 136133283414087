<template>
  <Dashboard title="Manage your team">
    <div class="mx-auto" v-if="team">
      <div>
        <div class="flex flex-row justify-end items-center p-6 border-b border-gray-600">
          <div class="flex flex-col flex-1">
            <div class="flex-1">
              <h2
                class="text-3xl font-bold tracking-tight sm:text-3xl text-white flex flex-row"
                v-if="!teamEditing"
              >
                {{ teamName }}
                <PencilSquareIcon
                  class="w-4 ml-2 text-gray-400 cursor-pointer"
                  @click="editTeamName"
                  v-if="isTeamCaptain"
                />
              </h2>
              <div v-if="teamEditing" class="flex flex-row">
                <input
                  name="editedTeamName"
                  v-model="editedTeamName"
                  class="pl-2 text-3xl font-bold tracking-tight sm:text-4xl text-white bg-gray-700 rounded"
                  :class="{ 'cursor-not-allowed': teamSaving }"
                />
                <button
                  class="ml-2 bg-primary-600 px-4 rounded"
                  @click="saveTeamName"
                  :disabled="teamSaving"
                >
                  <CheckIcon class="w-4 text-white" v-if="!teamSaving" />
                  <div class="flex flex-row" v-if="teamSaving">
                    <div
                      class="animate-spin rounded-full h-4 w-4 border-b-2 border-white"
                    ></div>
                  </div>
                </button>
              </div>
            </div>
            <p class="text-lg text-gray-500">
              Manage {{ teamName }} here. Everyone on your team has access to the same
              Refrag resources!
            </p>
          </div>

          <button
            v-if="!canAddTeamMember"
            class="bg-danger-500 hover:bg-danger-400 duration-300 px-3 py-2 mr-3 text-text-primary rounded-lg"
            @click="toggleLeaveTeamModal"
          >
            Leave Team
          </button>

          <div class="has-tooltip flex flex-col">
            <button
              type="button"
              class="text-white font-medium rounded-md text-sm px-5 py-2.5 text-center"
              :class="[canAddTeamMember ? 'bg-green-500' : 'bg-gray-400']"
              :disabled="!canAddTeamMember"
              v-on:click="toggleInviteModal"
            >
              +
            </button>
            <span
              v-if="canAddTeamMember"
              class="tooltip rounded shadow-md bg-gray-700 text-white p-3 mt-12 -ml-20 mr-4"
              >Add a team member</span
            >
            <span
              v-else-if="isCaptain"
              class="tooltip rounded shadow-md bg-gray-700 text-white p-3 mt-12 -ml-20 mr-4"
              >You cannot add more team members</span
            >
            <span
              v-else
              class="tooltip rounded shadow-md bg-gray-700 text-white p-3 mt-12 -ml-20 mr-4"
              >You need to be captain to add team members</span
            >
          </div>
        </div>
        <ul
          role="list"
          class="flex p-6 flex-wrap"
        >
          <li v-for="player in team.users" :key="player.name">
            <div class="ounded-lg text-center relative mt-4 mx-4 max-w-[12rem]">
              <div>
                <div :class="[player.id == user.id ? 'border-2 border-red-500' : '' , 'rounded p-8 bg-gray-900']">
                  <img
                    class="object-cover shadow-lg w-30 h-30 rounded-full"
                    :src="player.steam_profile?.avatarfull || refragIcon"
                    alt=""
                  />
                </div>

                <div class="space-y-2 py-2 text-center cursor-pointer" @click="$router.push(`/user/${getUserLink(player)}`)">
                  <div class="text-center">
                    <h3 class="text-white overflow-hidden px-4 text-ellipsis">
                      {{ getUsername(player) }}
                    </h3>
                    <p class="text-indigo-400 pb-2">{{ getUserRole(player.id) }}</p>
                    <ul role="list" class="flex justify-center space-x-5">
                      <li>
                        <a
                          :href="`mailto: ${player.email}`"
                          target="_blank"
                          class="text-gray-400 hover:text-gray-300"
                        >
                          <span class="sr-only">Email</span>
                          <AtSymbolIcon class="w-5 h-5" />
                        </a>
                      </li>
                      <li>
                        <a
                          :href="`https://steamcommunity.com/profiles/${player.steam_id}`"
                          target="_blank"
                          class="text-gray-400 hover:text-gray-300"
                        >
                          <span class="sr-only">Steam</span>
                          <svg
                            class="h-5 w-5"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-4.738 0-8.71-3.317-9.735-7.749l3.752 1.578c.244 1.235 1.338 2.182 2.67 2.171 1.477-.014 2.662-1.211 2.672-2.682l.006-.003 3.087-2.301c1.975.021 3.548-1.571 3.548-3.506 0-1.937-1.572-3.508-3.508-3.508-1.915 0-3.47 1.535-3.506 3.441l-2.263 3.166c-.572-.012-1.087.145-1.535.438l-5.123-2.155c.555-4.994 4.797-8.89 9.935-8.89 5.514 0 10 4.486 10 10s-4.486 10-10 10zm-2.753-8.091l-1.322-.555c.249-.094.492-.137.738-.137 1.138 0 2.074.926 2.084 2.065.01 1.153-.912 2.094-2.066 2.104-.867.004-1.602-.513-1.928-1.248l1.324.556c.769.323 1.653-.039 1.977-.807.324-.768-.037-1.654-.807-1.978zm2.861-4.401c0-1.315 1.069-2.384 2.384-2.384s2.384 1.069 2.384 2.384c0 1.314-1.069 2.385-2.384 2.385s-2.384-1.07-2.384-2.385zm2.384 1.801c-.994 0-1.802-.807-1.802-1.8s.808-1.801 1.802-1.801c.993 0 1.8.809 1.8 1.801 0 .992-.807 1.8-1.8 1.8z"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="getUserRole(player.id) === 'Player' && canRemoveTeamMember"
                  v-on:click="toggleRemoveModal(player)"
                  class="bg-gray-700 rounded-full w-6 h-6 absolute -top-2 -right-2 flex justify-center items-center cursor-pointer"
                >
                  <XMarkIcon class="text-red-700 w-4 h-4"></XMarkIcon>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="bg-gray-950 rounded py-10" v-if="teamInvites.length">
          <h3 class="text-lg leading-6 font-medium text-white p-6 bg-gray-900 shadow">Pending Invites</h3>
          <ul role="list" class="divide-y divide-gray-600">
            <TeamInviteVue
              @removeInvite="getTeamInvites"
              v-for="teamInvite in teamInvites"
              :key="teamInvite.id"
              :team-invite="teamInvite"
            />
          </ul>
        </div>
      </div>
    </div>
    <ModalWindow :open="inviteModalOpen" @toggleModal="toggleInviteModal">
      <InvitePlayer @toggleModal="toggleInviteModal" @inviteSuccessful="getTeamInvites" />
    </ModalWindow>
    <ModalWindow :open="removeModalOpen" @toggleModal="toggleRemoveModal">
      <RemovePlayer
        v-bind:removeUser="removeUser"
        @toggleModal="toggleRemoveModal"
        @removePlayerSucceeded="reloadTeam"
      />
    </ModalWindow>
    <ModalWindow :open="leaveTeamModalOpen">
      <h2 class="text-2xl text-text-primary font-semibold flex gap-2">
        <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
          <img class="w-4" src="../assets/icons/error.svg" alt="Refrag Icon" />
        </div>
        Leave the team
      </h2>
      <h3 class="text-text-secondary mt-3">
        You are about to leave the team. Are you sure you want to do this?
      </h3>
      <div class="flex flex-row justify-end mt-6 w-full">
        <button
          class="bg-danger-500 hover:bg-danger-400 duration-300 px-4 py-2 rounded w-full"
          @click="leaveTeam"
        >
          Yes, leave the team
        </button>
        <button
          class="bg-gray-800 hover:bg-gray-700 duration-300 px-4 py-2 ml-2 rounded w-full text-text-primary"
          @click="leaveTeamModalOpen = false"
        >
          No, stay on the team
        </button>
      </div>
    </ModalWindow>
  </Dashboard>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import ModalWindow from "../components/ModalWindow.vue";
import InvitePlayer from "../components/InvitePlayer.vue";
import RemovePlayer from "../components/RemovePlayer.vue";
import refragIcon from "../assets/logos/refrag-icon-button.svg";
import { AtSymbolIcon, PencilSquareIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import TeamInviteVue from "../components/TeamInvite.vue";

export default {
  head: {
    title: "Refrag | Manage your team",
  },
  data() {
    return {
      leaveTeamModalOpen: false,
      inviteModalOpen: false,
      removeModalOpen: false,
      removeUser: {},
      teamInvites: [],
      teamEditing: false,
      teamSaving: false,
      editedTeamName: "My Team",
      refragIcon: refragIcon,
      team: {},
    };
  },
  mounted() {
    this.getTeam();
  },
  components: {
    RemovePlayer,
    CheckIcon,
    Dashboard,
    InvitePlayer,
    ModalWindow,
    TeamInviteVue,
    PencilSquareIcon,
    AtSymbolIcon,
    XMarkIcon,
  },
  created() {
    if (this.user.access_level < 3) {
      this.$router.push("/");
    }
    this.getTeamInvites();
    this.editedTeamName = this.teamName;
  },
  computed: {
    ...mapState(["activeTeam"]),
    user() {
      return this.$auth.user() || {};
    },
    teamName() {
      return this.team.name || "My Team";
    },
    canAddTeamMember() {
      if (this.team?.users?.length >= 7) return false;
      if (!this.isTeamCaptain) return false;
      if (this.user.access_level < 3) return false;
      return true;
    },
    isTeamCaptain() {
      return this.user.id === this.team.captain_id;
    },
    canRemoveTeamMember() {
      if (this.user.id != this.team.captain_id) return false;
      if (this.user.access_level < 3) return false;
      return true;
    },
    isCaptain() {
      return this.team.captain_id === this.user.id;
    },
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    getUserRole(user_id) {
      return this.team.captain_id == user_id ? "Captain" : "Player";
    },
    getUserLink(user) {
      if(user.username) {
        return user.username
      }
      return user.uuid
    },
    leaveTeam() {
      axios({
        method: "delete",
        url: `/teams/${this.team.id}/leave_team`,
        headers: {
          "X-TEAM-ID": this.team.id,
        },
      })
        .then((response) => {
          this.$auth.fetch().then(() => {
            this.$router.push("/");
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsername(user) {
      if (user.username) {
        return user.username
      }
      return user.steam_profile?.personaname;
    },
    toggleLeaveTeamModal() {
      this.leaveTeamModalOpen = !this.leaveTeamModalOpen;
    },
    toggleInviteModal() {
      this.inviteModalOpen = !this.inviteModalOpen;
    },
    toggleRemoveModal(user) {
      this.removeUser = user;
      this.removeModalOpen = !this.removeModalOpen;
    },
    reloadTeam() {
      this.$auth.fetch();
    },
    getTeamInvites() {
      axios({
        method: "get",
        url: "/team_invites",
        headers: {
          "X-TEAM-ID": this.activeTeam?.id,
        },
      })
        .then((response) => {
          this.teamInvites = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTeam() {
      axios({
        method: "get",
        url: "/teams/active",
        headers: {
          "X-TEAM-ID": this.activeTeam?.id,
        },
      })
        .then((response) => {
          this.team = response.data.data;
        })
        .catch((error) => {
          console.log(error);
      })
    },
    saveTeamName() {
      this.teamSaving = true;
      axios({
        method: "put",
        url: "/teams/" + this.activeTeam?.id,
        headers: {
          "X-TEAM-ID": this.activeTeam?.id,
        },
        data: {
          team: {
            name: this.editedTeamName,
          },
        },
      })
        .then((response) => {
          this.$auth.fetch().then(() => {
            this.teamEditing = false;
            this.teamSaving = false;
            this.showMessage({
              messageType: "success",
              messageText: `Your team name was changed to ${this.editedTeamName}`,
            });
            setTimeout(() => {
              this.hideMessage();
            }, 3000);
          });
        })
        .catch((error) => {
          this.showMessage({
            messageType: "error",
            messageText: `Could not change your team name to ${this.editedTeamName}`,
          });
          setTimeout(() => {
            this.hideMessage();
          }, 3000);
          console.log(error);
        });
    },
    editTeamName() {
      this.teamEditing = true;
    },
  },
};
</script>
