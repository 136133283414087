<template>
  <div v-if="loading" class="flex justify-center py-10">
    <svg class="w-16 h-16 text-text-primary animate-spin" fill="none"
        viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            fill="currentColor">
      </path>
    </svg>
  </div>
  <div class="flex flex-col gap-4" v-else>
    <h1 class="text-text-primary font-semibold text-[24px]">Statistics</h1>
    <div id="statCharts" class="flex flex-col gap-6">
      <dl class="grid grid-cols-1 lg:grid-cols-6 gap-6">
        <StatBlock
          subtitle="Time to Kill"
          :title="averageTimeToKill()"
          :isActive="isActive(2)"
          :class="activeClass(2)"
          @click="setActiveStat(2)"
        />
        <StatBlock
          subtitle="Headshot Percentage"
          :title="averageHeadshotPercentage()"
          :isActive="isActive(0)"
          :class="activeClass(0)"
          @click="setActiveStat(0)"
        />
        <StatBlock
          subtitle="Time to Damage"
          :title="averageTimeToDamage()"
          :isActive="isActive(1)"
          :class="activeClass(1)"
          @click="setActiveStat(1)"
        />
        <StatBlock
          subtitle="Reaction Time"
          :title="averageReactionTime()"
          :isActive="isActive(5)"
          :class="activeClass(5)"
          @click="setActiveStat(5)"
        />
        <StatBlock
          subtitle="Crosshair Placement"
          :title="averageCrosshairDrift()"
          :isActive="isActive(3)"
          :class="activeClass(3)"
          @click="setActiveStat(3)"
        />
        <StatBlock
          subtitle="Counter-Strafing"
          :title="averageCounterStrafing()"
          :isActive="isActive(4)"
          :class="activeClass(4)"
          @click="setActiveStat(4)"
        />
      </dl>
      <div id="statCharts" class="flex flex-col gap-4">
        <div class="flex flex-row items-center">
          <h3 class="text-xl flex-grow leading-6 font-medium text-text-primary shadow">{{ chartTitle() }}</h3>
          <div class="flex gap-2">
            <div>
              <Listbox as="div"
                v-model="gameMode"
              >
                <div class="relative">
                  <ListboxButton
                    class="relative bg-gray-800 border border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none sm:text-sm"
                  >
                    <span class="flex items-center text-gray-200">
                      <span class="block truncate">{{ gameModeTitle() }}</span>
                    </span>
                    <span
                      class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                    >
                      <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute z-10 mt-1 bg-gray-800 shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      <ListboxOption
                        as="template"
                        v-for="gameModeOption in gameModes()"
                        :key="gameModeOption"
                        :value="gameModeOption"
                      >
                        <li
                          :class="[
                            gameMode == gameModeOption
                              ? 'text-text-primary'
                              : 'text-gray-200 hover:text-text-primary hover:bg-gray-700',
                            'cursor-pointer select-none relative py-2 pl-3 pr-9',
                          ]"
                        >
                          <div class="flex items-center">
                            <span
                              :class="[
                                gameMode == gameModeOption ? 'font-semibold' : 'font-normal',
                                'block truncate',
                              ]"
                            >
                              {{ gameModeName(gameModeOption) }}
                            </span>
                          </div>

                          <span
                            v-if="gameMode == gameModeOption"
                            class="absolute inset-y-0 right-0 flex items-center pr-4 text-text-primary"
                          >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
            <div>
              <Listbox as="div"
                v-model="timeFrame"
              >
                <div class="relative">
                  <ListboxButton
                    class="relative bg-gray-800 border border-gray-700 rounded-md shadow-sm py-2 h-10 items-center text-left flex gap-2 px-4 cursor-pointer focus:outline-none sm:text-sm"
                  >
                    <span class="flex items-center text-gray-200">
                      <span v-if="timeFrame" class=" block truncate">Past {{ timeFrame }} days</span>
                      <span v-else class=" block truncate">Overall</span>
                    </span>
                    <span
                      class="ml-3 inset-y-0 right-0 flex items-center pointer-events-none"
                    >
                      <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition ease-in duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      class="absolute cursor-pointer z-10 mt-1 bg-gray-800 shadow-lg max-h-72 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-40"
                    >
                      <ListboxOption
                        as="template"
                        value="7"
                      >
                        <div class="py-2 px-4 text-text-primary hover:bg-gray-600">
                          Past 7 Days
                        </div>
                      </ListboxOption>
                      <ListboxOption
                        as="template"
                        value="30"
                      >
                        <div class="py-2 px-4 text-text-primary hover:bg-gray-600">
                          Past 30 Days
                        </div>
                      </ListboxOption>
                      <ListboxOption
                        as="template"
                        value="60"
                      >
                        <div class="py-2 px-4 text-text-primary hover:bg-gray-600">
                          Past 60 Days
                        </div>
                      </ListboxOption>
                      <ListboxOption
                        as="template"
                        value=""
                      >
                        <div class="py-2 px-4 text-text-primary hover:bg-gray-600 ">
                          Overall
                        </div>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        <div class="bg-gray-900 rounded-lg">
          <div
            class="rounded bg-transparent p-12 flex-1"
            v-if="props.statistics"
          >
            <LineChart :options="options" :chartData="getChartData()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
  import moment from "moment";
  import { useAuth } from "@websanova/vue-auth/src/v3.js";
  import PrefireIcon from "../../assets/icons/prefire.svg";
  import CrossfireIcon from "../../assets/icons/crossfire.svg";
  import OverallIcon from "../../assets/icons/globe.svg";
  import DeathmatchIcon from "../../assets/icons/dm.svg";
  import { ref, watch } from "vue";
  import StatBlock from "../Restrat/Demo/StatBlock.vue";
  import { LineChart } from "vue-chart-3";
  import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  } from "@headlessui/vue";
  import { CheckIcon, ChevronUpDownIcon } from "@heroicons/vue/20/solid";
  import { Chart, registerables } from "chart.js";
  
  Chart.register(...registerables);
  
  const auth = useAuth();
  
  const activeStat = ref(2);
  const gameMode = ref('');
  const timeFrame = ref('30');
  const stats = [{}];
  const loading = ref(false);
  let options = {
    spanGaps: true,
    plugins: {
      legend: {
        labels: {
          color: "#9CADC4",
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'transparent',
          borderColor: 'transparent'
        },
        ticks: {
          color: '#9CADC4'
        },
      },
      y: {
        suggestedMin: 0,
        grid: {
          borderDash: [5, 5],
          color: '#1E2733',
          borderColor: 'transparent'
        },
        ticks: {
          color: '#9CADC4'
        },
      }
    }
  }
  
  const props = defineProps({
    statistics: {
      type: Object
    }
  })

  const emit = defineEmits(['updateMod', 'updateTimeFrame'])

  function isActive(index) {
    return index == activeStat.value;
  }
  
  function activeClass(index) {
    if (isActive(index)) {
      return "activeStat";
    }
    return "inactiveStat";
  }
  
  function setActiveStat(index) {
    activeStat.value = index;
  }
  
  function gameModeName(gameMode) {
    switch (gameMode) {
      case "crossfire":
        return "Crossfire Statistics";
      case "prefire":
        return "Prefire Statistics";
      case "deathmatch":
        return "Deathmatch Statistics";
      default:
        return "Overall Statistics";
    }
  }
  
  function averageHeadshotPercentage() {
    if (props.statistics?.average_headshot_percentage == undefined) {
      return "";
    }
    return `${Math.round(props.statistics?.average_headshot_percentage)}%`;
  }
  
  function averageTimeToDamage() {
    if (props.statistics?.average_time_to_damage == undefined) {
      return "";
    }
    return `${parseFloat(props.statistics?.average_time_to_damage).toFixed(2)} sec`;
  }
  
  function averageTimeToKill() {
    if (props.statistics?.average_time_to_kill == undefined) {
      return "";
    }
    return `${parseFloat(props.statistics?.average_time_to_kill).toFixed(2)} sec`;
  }
  
  function averageCrosshairDrift() {
    if (props.statistics?.average_crosshair_drift == undefined) {
      return "";
    }
    return `${parseFloat(props.statistics?.average_crosshair_drift).toFixed(2)} deg`;
  }
  
  function averageCounterStrafing() {
    if (props.statistics?.average_counter_strafe == undefined) {
      return "";
    }
    return `${Math.round(props.statistics?.average_counter_strafe)}%`;
  }

  function averageReactionTime() {
    if (props.statistics?.average_reaction_time == undefined) {
      return "";
    }
    return `${parseFloat(props.statistics?.average_reaction_time).toFixed(2)} sec`;
  }
  
  function totalAttempts() {
    return props.statistics?.statistics_count;
  }
  
  function chartTitle() {
    switch (activeStat.value) {
      case 0:
        return "Headshot Percentage";
      case 1:
        return "Time to Damage";
      case 2:
        return "Time to Kill";
      case 3:
        return "Crosshair Placement";
      case 4:
        return "Counter-Strafing";
      case 5:
        return "Reaction Time";
      default:
        return "Charts";
        break;
    }
  }
  
  function getChartData() {
    return {
      labels: props.statistics?.statistics.map((stat) =>
        moment(stat.created_at).format("LLL")
      ),
      datasets: [
        {
          label: chartTitle(),
          data: chartDataData(),
          color: "white",
          fill: true,
          backgroundColor: "#6B70D115",
          borderColor: "#6B70D1",
          tension: 0.1,
        },
      ],
    }
  }
  
  function chartDataData() {
    if (!props.statistics?.statistics) {
      return [];
    }

    let reversedStats = props.statistics.statistics;
    switch (activeStat.value) {
      case 0:
        return reversedStats.map(
          (stat) =>
            (stat["total_headshots"] /
              (stat["total_headshots"] + stat["total_bodyshots"])) *
            100
        );
      case 1:
        return reversedStats.map((stat) => stat.average_time_to_damage);
      case 2:
        return reversedStats.map((stat) => stat.average_time_to_kill);
      case 3:
        return reversedStats.map((stat) => stat.average_crosshair_drift);
      case 4:
        return reversedStats.map((stat) => stat.average_counter_strafe);
      case 5:
        return reversedStats.map((stat) => stat.average_reaction_time);
      default:
        return [];
    }
  }
  
  function gameModeTitle() {
    switch (gameMode.value) {
      case "crossfire":
        return "Crossfire Statistics";
      case "prefire":
        return "Prefire Statistics";
      case "deathmatch":
        return "DM Statistics"
      default:
        return "Overall Statistics";
        break;
    }
  }
  
  function gameModes() {
    return ['', "crossfire", "prefire","deathmatch"];
  }
  
  watch(() => gameMode.value, () => {
    if (loading.value) return;
    loading.value = true
    
    emit('updateMod', gameMode.value)

    setTimeout(() => {
      loading.value = false
    }, 1000);
  
  })

  watch(() => timeFrame.value, () => {
    if (loading.value) return;
    loading.value = true
    
    emit('updateTimeFrame', timeFrame.value)

    setTimeout(() => {
      loading.value = false
    }, 300);
  })
  
  </script>
  
  <style scoped>
  .gameIcon {
    filter: invert(74%);
  }
  .activeGameIcon {
    filter: invert(100%);
  }
  </style>
  