<template>
  <Dashboard>
    <div v-if="loading">
      <svg
        width="64"
        height="64"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="animate-spin"
      >
        <circle cx="16" cy="16" r="15" stroke="#1E2733" stroke-width="2" />
        <path
          d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43986 29.8582 10.2597C30.612 12.0796 31 14.0302 31 16"
          stroke="#4E54C8"
          stroke-width="2"
        />
      </svg>
    </div>
    <div v-else class="flex flex-col gap-8 w-full">
      <div class="flex flex-col lg:flex-row gap-3 items-center">
        <div class="flex flex-col gap-3 flex-1 items-start w-full">
          <button
            @click="router.push({ name: 'BootcampsPage' })"
            class="bg-gray-800 text-text-primary text-xs px-3 py-2.5 rounded-lg flex gap-2 items-center hover:bg-gray-700 duration-300"
          >
            <img src="../../assets/icons/leftarrow.svg" alt="back" class="w-3 h-3" />
            Back to Bootcamps
          </button>
          <h1 class="text-4xl font-medium text-text-primary">{{ bootcamp?.name }}</h1>
          <div class="flex gap-2">
            <h1
              class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1"
            >
              <img src="../../assets/icons/user.svg" alt="beginner" class="w-4 h-4" />
              {{ bootcamp?.students_count }} Students
            </h1>
            <h1
              class="px-2 py-1 bg-gray-800 text-text-secondary text-xs rounded-lg flex gap-1"
            >
              <img
                src="../../assets/icons/speedometor.svg"
                alt="beginner"
                class="w-4 h-4"
              />
              {{ difficultiesString }}
            </h1>
          </div>
          <p class="text-text-secondary text-sm font-light">
            {{ bootcamp?.description }}
          </p>
        </div>
        <button
          @click="startBootcamp()"
          class="ml-auto items-center w-full lg:w-auto inline-flex justify-center py-3 px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-text-primary bg-primary-600 hover:bg-primary-500"
        >
          <svg
            v-if="launchLoading"
            class="animate-spin h-full font-normal w-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <HandThumbUpIcon v-else-if="launchSuccess" class="w-3 h-3 mr-1 text-white" />
          <img v-else src="../../assets/icons/play.svg" class="w-3 h-3 mr-1 brightness-200">
          {{ userEnrolled ? 'Continue' : 'Start' }}
        </button>
        <!-- <div
          v-else
          class="ml-auto items-center inline-flex justify-center py-3 px-20 border border-transparent shadow-sm text-sm font-medium rounded-md text-text-primary/30 bg-primary-600/30"
        >
          Enrolled
        </div> -->
      </div>
      <div class="flex lg:hidden">
        <Menu as="div" class="relative inline-block text-left w-full lg:w-auto">
          <MenuButton
            class="relative w-full flex h-12 items-center px-2 py-3 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
          >
            <div class="flex-1 text-left font-light">
              <span class="text-text-secondary">Difficulty:</span> {{ getFriendlyDifficultyName(selectedDifficulty) }}
            </div>
            <ChevronDownIcon class="-mr-1 ml-4 h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-50 mt-2 w-full lg:w-[192px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div class="py-1 flex flex-col px-4">
                <MenuItem
                  :key="difficulty"
                  v-for="difficulty in bootcamp?.difficulties"
                  v-slot="{ active }"
                  @click="updateDifficulty(difficulty)"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'text-text-primary' : 'text-text-secondary',
                      'group flex items-center py-2 text-sm w-full justify-start',
                    ]"
                  >
                    {{ getFriendlyDifficultyName(difficulty) }}
                    <CheckIcon v-if="difficulty === selectedDifficulty" class="h-5 w-5 ml-auto text-primary-400" />
                  </a>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
      <div class="hidden lg:flex gap-9 w-full border-b border-gray-800 pb-4 relative">
        <div
          v-for="difficulty in bootcamp?.difficulties"
          @click="updateDifficulty(difficulty)"
          class="gap-2 items-center cursor-pointer flex"
          :ref="(el) => setRef(el, difficulty)"
        >
          <h1
            :class="[
              selectedDifficulty == difficulty
                ? 'text-text-primary duration-300'
                : 'text-text-secondary duration-300',
            ]"
          >
            {{ getFriendlyDifficultyName(difficulty) }}
          </h1>
          <Pill
            v-if="userEnrolled"
            class="p-1"
            :text="getDifficultyPercentComplete(difficulty).toFixed(0) + '%'"
            :bgColor="getDifficultyColor(difficulty) + '15'"
            :textColor="getDifficultyColor(difficulty)"
          />
        </div>
        <div class="absolute flex-row md:flex bottom-0">
          <div
            class="h-[2px] grow-transition"
            :style="{ width: getSectionUnderlinePadding() + 'px' }"
          />
          <div
            ref="sectionUnderline"
            class="h-[2px] grow-transition transition-all duration-300"
            :style="{
              width: getSectionUnderlineWidth() + 'px',
              backgroundColor: getDifficultyColor(selectedDifficulty),
            }"
          />
        </div>
      </div>

      <div class="flex flex-col lg:flex-row gap-6">
        <div class="flex flex-col gap-12 w-full lg:w-[360px]">
          <div v-if="userEnrolled" class="flex flex-col gap-4">
            <h1 class="text-[24px] font-medium text-text-primary tracking-[0.5px]">
              Exam
            </h1>
            <div
              class="rounded-lg bg-gray-900 items-center flex flex-col divide-y-2 divide-gray-950"
            >
              <div class="flex flex-col py-8 px-4 gap-4 items-center">
                <div
                  class="flex items-center justify-center h-20 w-20 rounded-full bg-gray-800 border border-gray-700"
                >
                  <img src="../../assets/icons/exam.svg" alt="exam" class="w-9 h-9" />
                </div>
                <h1 class="text-text-primary text-xl font-semibold">
                  Ready to test your skills?
                </h1>
                <p class="text-text-secondary text-sm font-light text-center px-12">
                  Take the exam to test your skills and get certified.
                </p>
              </div>
              <div class="flex flex-col p-4 w-full">
                <button
                  @click="
                    router.push({
                      name: 'BootcampExamPage',
                      params: { slug: bootcamp.slug, difficulty: selectedDifficulty },
                    })
                  "
                  class="items-center inline-flex justify-center py-3 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-text-primary bg-primary-600 hover:bg-primary-500"
                >
                  Go to exam
                  <img
                    src="../../assets/icons/rightarrow.svg"
                    alt="arrow-right"
                    class="ml-2 brightness-200 w-3 h-3"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <h1 class="text-[24px] font-medium text-text-primary tracking-[0.5px]">
              Details
            </h1>
            <div class="rounded-lg bg-gray-900 overflow-hidden">
              <div class="w-full aspect-[2.25] bg-gray-800">
                <img
                  :src="bootcamp?.image_url"
                  alt="bootcamp"
                  class="object-cover w-full h-full"
                />
              </div>
              <div class="flex flex-col gap-3">
                <h1
                  class="text-text-secondary text-xs tracking-[1.5px] font-light px-4 pt-6"
                >
                  What you will learn:
                </h1>
                <div
                  v-for="section in bootcamp?.sections"
                  class="flex gap-2 items-center px-4 pb-2"
                >
                  <CheckIcon class="text-primary-500 h-5 w-5" />
                  <h2 class="text-text-primary">{{ section.name }}</h2>
                </div>
                <div class="flex items-center border-t-2 border-gray-950 justify-center">
                  <div
                    class="flex flex-col gap-2 items-center justify-center flex-1 py-4 border-r border-gray-950"
                  >
                    <img
                      src="../../assets/icons/task.svg"
                      alt="arrow-down"
                      class="w-5 h-5"
                    />
                    <h1 class="text-text-primary text-xs">{{ taskCount }} Tasks</h1>
                  </div>
                  <div
                    class="flex flex-col gap-2 items-center justify-center flex-1 py-4 border-l border-gray-950"
                  >
                    <img
                      src="../../assets/icons/time.svg"
                      alt="arrow-down"
                      class="w-5 h-5"
                    />
                    <h1 class="text-text-primary text-xs">
                      {{ estimatedDurationInMinutes }} minutes
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 flex-1">
          <h1 class="text-[24px] font-medium text-text-primary tracking-[0.5px]">
            Task List
          </h1>
          <div
            v-for="(section, index) in bootcamp?.sections"
            class="bg-gray-900 rounded-lg overflow-hidden flex flex-col"
          >
            <div class="flex items-center py-4 px-6 border-b-2 border-gray-950 gap-2">
              <div class="flex flex-col flex-1">
                <h2 class="text-text-secondary text-xs">Section {{ index + 1 }}</h2>
                <h1 class="text-text-primary font-medium">{{ section.name }}</h1>
              </div>
              <Pill
                v-if="userEnrolled"
                class="p-1 ml-auto"
                :text="getSectionStatusText(section)"
                :bgColor="getSectionStatusColor(section) + '20'"
                :textColor="getSectionStatusColor(section)"
              />
              <Pill
                class="p-1 ml-auto"
                :text="tasksForDifficulty(section.tasks).length + ' Tasks'"
                bgColor="#131A20"
                textColor="#9CADC4"
              />
            </div>

            <Disclosure
              v-for="task in tasksForDifficulty(section.tasks)"
              v-slot="{ open }"
              as="dif"
              class="border-b-2 border-gray-950"
            >
              <DisclosureButton
                class="flex py-6 px-6 w-full gap-2 items-center"
                :class="[open ? 'bg-gray-800' : 'bg-gray-900']"
              >
                <div v-if="userEnrolled">
                  <CheckCircleIcon
                    v-if="task.user_completion_status == 'passed'"
                    class="w-4 h-4 text-text-disabled"
                  />
                  <XCircleIcon
                    v-else-if="task.user_completion_status == 'failed'"
                    class="w-4 h-4 text-red-500"
                  />
                  <div
                    v-else-if="task.user_completion_status == 'not_started'"
                    class="w-4 h-4 rounded-full border-text-primary border-2"
                  />
                </div>
                <h1
                  class="flex-1 text-left"
                  :class="[
                    task.user_completion_status == 'passed'
                      ? 'text-text-disabled'
                      : 'text-text-primary',
                  ]"
                >
                  {{ task.name }}
                </h1>
                <ChevronDownIcon
                  class="w-5 h-5 duration-300"
                  :class="[
                    open ? 'transform rotate-180 text-text-primary' : 'text-gray-300',
                  ]"
                />
              </DisclosureButton>
              <DisclosurePanel class="flex flex-col gap-6 px-6 py-6">
                <h1 class="text-text-secondary text-sm font-light">
                  {{ task.description }}
                </h1>
                <button
                  v-if="userEnrolled"
                  @click="launchTask(task)"
                  class="ml-auto items-center inline-flex justify-center py-3 w-full border border-transparent shadow-sm text-sm font-medium rounded-md text-text-primary bg-primary-600 hover:bg-primary-500"
                >
                  <img
                    src="../../assets/icons/play.svg"
                    alt="arrow-right"
                    class="mr-2 brightness-200 w-3 h-3"
                  />
                  Start Task
                  <div v-if="launchLoading == task.id" class="flex items pl-2">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="animate-spin"
                    >
                      <circle cx="12" cy="12" r="10" stroke="#1E2733" stroke-width="2" />
                      <path
                        d="M12 2C14.1217 2 16.1566 2.84285 17.8284 4.51472C19.5003 6.18658 20.3431 8.22145 20.3431 10.3432"
                        stroke="#4E54C8"
                        stroke-width="2"
                      />
                    </svg>
                  </div>
                  <div v-if="launchSuccess == task.id" class="flex items pl-2">
                    <HandThumbUpIcon class="w-4 h-4 text-success-400" />
                  </div>
                </button>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
  </Dashboard>
  <StartServerModal
    :server="serverInfo"
    :show="showServerModal"
    @close="showServerModal = false"
  />
</template>

<script setup>
import Dashboard from "../../components/layouts/DashboardLayout.vue";
import StartServerModal from "../../components/StartServerModal.vue";
import axios from "axios";
import { ref, reactive, computed, getCurrentInstance, onBeforeUnmount } from "vue";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useRouter, useRoute } from "vue-router";
import Pill from "../../components/Pill.vue";
import { useStore } from "vuex";
import { CheckIcon, MinusIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";
import { CheckCircleIcon, HandThumbUpIcon, XCircleIcon } from "@heroicons/vue/20/solid";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { useHead } from '@unhead/vue'

const auth = useAuth();
const router = useRouter();
const route = useRoute();
const user = ref(auth.user());
const store = useStore();
const bootcamp = ref(null);
const loading = ref(true);
const difficultyDivs = reactive({});
const selectedDifficulty = ref(null);
const sectionUnderline = ref(null);
const sectionUnderlineWidth = ref(null);
const sectionUnderlinePadding = ref(0);
const userEnrolled = ref(false);
const serverInfo = ref();
const showServerModal = ref(false);
const activeServer = computed(() => store.getters.activeServer);
const websocket = ref(null);
const subscription = ref(null);
const instance = getCurrentInstance();
const launchLoading = ref(false);
const launchSuccess = ref(false);

if (user.value?.access_level < 1) {
  router.push({ name: "Dashboard" });
}

if (route.query.difficulty) {
  updateDifficulty(route.query.difficulty);
} else {
  updateDifficulty('beginner');
}

useHead({
  title: () => bootcamp.value ? `Refrag | ${bootcamp.value.name}` : "Refrag Bootcamp",
  meta: [
    {
      name: "description",
      content: () => bootcamp.value ? bootcamp.value.description : "Refrag is your ultimate training tool, designed to enhance your skills, boost your confidence, and turn every gaming session into an epic adventure.",
    },
  ],
})

function getBootcamp() {
  loading.value = true;
  axios({
    url: `/task_lists/${route.params.slug}`,
    method: "GET",
    params: {
      is_exam: false,
    },
  }).then((response) => {
    loading.value = false;
    bootcamp.value = response.data;
    userEnrolled.value = response.data.enrolled;
    subscribeToBootcamp();
  });
}

function subscribeToBootcamp() {
  const cable = instance.appContext.config.globalProperties.$cable;

  if (!websocket.value) {
    websocket.value = cable.connect();
  }
  if (!subscription.value) {
    subscription.value = websocket.value.subscriptions.create(
      { channel: "BootcampChannel", user_id: user.value.id },
      {
        received: (data) => {
          if (data.event === "task_list_updated") {
            getBootcamp();
          }
        },
      }
    );
  }
}

function startBootcamp() {
  if (!userEnrolled.value) {
    axios({
      url: `/task_lists/${route.params.slug}/enroll`,
      method: "POST",
    }).then((response) => {
      userEnrolled.value = response.data.enrolled;
    });
  }
  launchTask(getCurrentTask());
}

function getCurrentTask() {
  return bootcamp.value?.sections.flatMap((section) =>
    section.tasks.filter((task) => task.user_completion_status == "not_started" && task.difficulty == selectedDifficulty.value)
  )[0];
}

function getDifficultyPercentComplete(difficulty) {
  return (
    (allTasksForDifficulty(difficulty).filter(
      (task) => task.user_completion_status == "passed"
    ).length /
      allTasksForDifficulty(difficulty).length) *
    100
  );
}

const difficultiesString = computed(() => {
  if (bootcamp.value?.slug === "dust") {
    return "Beginner";
  }

  return (
    bootcamp.value?.difficulties
      .map((difficulty) => getFriendlyDifficultyName(difficulty))
      .join(", ") || ""
  );
});

function tasksForDifficulty(tasks) {
  return tasks
    .filter((task) => task.difficulty === selectedDifficulty.value)
    .sort((a, b) => a.step - b.step);
}

function allTasksForDifficulty(difficulty) {
  return bootcamp.value?.sections.flatMap((section) =>
    section.tasks.filter((task) => task.difficulty === difficulty)
  );
}

function completionPercentageOfTasks(tasks) {
  return (
    (tasks.filter((task) => task.user_completion_status == "passed").length /
      tasks.length) *
    100
  ) || 0;

}

const estimatedDurationInMinutes = computed(() => {
  return (
    bootcamp.value?.sections
      .flatMap((section) =>
        section.tasks
          .filter((task) => task.difficulty === selectedDifficulty.value)
          .reduce((acc, task) => acc + task.estimated_duration_in_minutes, 0)
      )
      .reduce((acc, duration) => acc + duration, 0) || 0
  );
});

const taskCount = computed(() => {
  return (
    bootcamp.value?.sections.flatMap((section) =>
      section.tasks.filter((task) => task.difficulty === selectedDifficulty.value)
    ).length || 0
  );
});

function getSectionStatusText(section) {
  let percentComplete = completionPercentageOfTasks(tasksForDifficulty(section.tasks));
  switch (percentComplete) {
    case 100:
      return "Completed";
    case 0:
      return "Not Started";
    default:
      return "In Progress";
  }
}

function getSectionStatusColor(section) {
  let percentComplete = completionPercentageOfTasks(tasksForDifficulty(section.tasks));
  switch (percentComplete) {
    case 100:
      return "#25E5CE";
    case 0:
      return "#9CADC4";
    default:
      return "#3AB8F3";
  }
}

function updateDifficulty(difficulty) {
  selectedDifficulty.value = difficulty;
  updateUnderlineStyle();
  router.push({ query: { difficulty: difficulty } });
}

function getDifficultyColor(difficulty) {
  switch (difficulty) {
    case "beginner":
      return "#B8C7D5";
    case "intermediate":
      return "#B563FF";
    case "advanced":
      return "#FBD500";
  }
}

function updateUnderlineStyle() {
  const ref = difficultyDivs[selectedDifficulty.value];
  if (!ref) return;
  const refWidth = ref.offsetWidth;
  const refLeft = ref.offsetLeft;
  sectionUnderlineWidth.value = refWidth;
  sectionUnderlinePadding.value = refLeft;
}

function getSectionUnderlineWidth() {
  return sectionUnderlineWidth.value;
}

function getSectionUnderlinePadding() {
  return sectionUnderlinePadding.value;
}

function getFriendlyDifficultyName(difficulty) {
  return difficulty.charAt(0).toUpperCase() + difficulty.slice(1);
}

function setRef(element, refName) {
  if (element) {
    difficultyDivs[refName] = element;
    updateDifficulty(selectedDifficulty.value);
  }
}

function launchTask(task) {
  if (!activeServer.value?.id) {
    serverInfo.value = {
      cs_map: task.cs_map,
      mode: task.mod,
      content: {
        id: parseInt(task.id),
        title: task.name,
        type: "task",
        task_list_id: bootcamp.value.id,
        task_list_section_id: task.section_id,
        task_list_difficulty: getTaskDifficulty(),
        is_exam: false,
      },
    };
    showServerModal.value = true;
    return;
  }

  launchLoading.value = task.id;
  axios({
    url: `/cs_servers/${activeServer.value?.id}/exec_server_command`,
    method: "POST",
    timeout: 5000,
    params: {
      command: "launch_task",
      value: `${bootcamp.value.id}, ${task.section_id}, ${
        task.id
      }, ${getTaskDifficulty()}, 0`,
      is_client_command: false,
    },
    headers: {
      "X-TEAM-ID": store.state.activeTeam.id,
    },
  })
    .then(() => {
      launchLoading.value = false;
      launchSuccess.value = task.id;
      setTimeout(() => {
        launchSuccess.value = false;
      }, 2000);
    })
    .catch((err) => {
      launchLoading.value = false;
      store.commit("showMessage", {
        messageType: "error",
        messageText: `Could not execute server command!`,
      });
    });
}

function getTaskDifficulty() {
  switch (selectedDifficulty.value) {
    case "beginner":
      return 0;
    case "intermediate":
      return 1;
    case "advanced":
      return 2;
  }
}

function cleanupWebsocket() {
  if (subscription.value) {
    subscription.value.unsubscribe();
    subscription.value = null;
  }
  if (websocket.value) {
    websocket.value.disconnect();
    websocket.value = null;
  }
}

onBeforeUnmount(() => {
  cleanupWebsocket();
});

getBootcamp();
</script>
